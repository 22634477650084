import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import axios from "axios";
import { hashPassword } from "../utils/passwordUtils";

type AuthContextType = {
  user: any;
  isAuthenticated: boolean;
  accessToken: string | null;
  loading: boolean;
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
  refreshAccessToken: () => Promise<string | null>;
};

const AuthContext = createContext<AuthContextType>({
  user: null,
  isAuthenticated: false,
  accessToken: null,
  loading: true,
  login: async () => {},
  logout: () => {},
  refreshAccessToken: async () => null,
});

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const [user, setUser] = useState<any>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [tokenExpiryTime, setTokenExpiryTime] = useState<number | null>(null);

  const getTokenExpiry = (token: string): number => {
    try {
      const payload = JSON.parse(atob(token.split(".")[1]));
      return payload.exp * 1000; // Convert to milliseconds
    } catch {
      return 0;
    }
  };

  const fetchUserData = async (token: string) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/users/me`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const userData = response.data;
      setUser(userData);
      setIsAuthenticated(true);
    } catch (error) {
      console.error("Failed to fetch user data:", error);
      logout();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      const expiry = getTokenExpiry(token);
      if (Date.now() >= expiry) {
        refreshAccessToken();
      } else {
        setTokenExpiryTime(expiry);
        setAccessToken(token);
        fetchUserData(token);
      }
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!accessToken || !tokenExpiryTime) return;

    const REFRESH_TIME_BEFORE_EXPIRY = 5 * 60 * 1000; // 5 minutes
    const timeUntilRefresh =
      tokenExpiryTime - Date.now() - REFRESH_TIME_BEFORE_EXPIRY;

    if (timeUntilRefresh <= 0) {
      refreshAccessToken();
      return;
    }

    const refreshTimer = setTimeout(() => {
      refreshAccessToken();
    }, timeUntilRefresh);

    return () => clearTimeout(refreshTimer);
  }, [accessToken, tokenExpiryTime]);

  const login = async (email: string, password: string) => {
    try {
      // First get CSRF token
      const csrfResponse = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/users/csrf-token`,
        { withCredentials: true }
      );
      const csrfToken = csrfResponse.data.csrfToken;

      // Hash password and make login request with CSRF token
      const hashedPassword = hashPassword(password);
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/users/login`,
        { email, hashedPassword },
        {
          withCredentials: true,
          headers: {
            "CSRF-Token": csrfToken,
          },
        }
      );

      const { accessToken, username, email: userEmail, role } = response.data;

      setTokenExpiryTime(getTokenExpiry(accessToken));
      localStorage.setItem("accessToken", accessToken);
      setUser({ username, userEmail, role });
      setAccessToken(accessToken);
      setIsAuthenticated(true);

      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        throw new Error(error.response.data.message || "Login failed");
      }
      throw new Error("Login failed");
    }
  };

  const logout = () => {
    localStorage.removeItem("accessToken");
    document.cookie = "refreshToken=; Max-Age=0; path=/;";
    setUser(null);
    setAccessToken(null);
    setIsAuthenticated(false);
    setTokenExpiryTime(null);
  };

  const refreshAccessToken = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/users/refresh-token`,
        {},
        { withCredentials: true }
      );
      const { accessToken } = response.data;

      setTokenExpiryTime(getTokenExpiry(accessToken));
      setAccessToken(accessToken);
      localStorage.setItem("accessToken", accessToken);

      return accessToken;
    } catch (error) {
      console.error("Failed to refresh access token:", error);
      logout();
      throw error;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        isAuthenticated,
        accessToken,
        loading,
        login,
        logout,
        refreshAccessToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
