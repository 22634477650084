import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { DateRangePicker } from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { useApi } from "../../../utils/api";
import { all_routes } from "../../router/all_routes";
import Table from "../../../core/common/dataTable";
import TooltipOption from "../../../core/common/tooltipOption";
import CustomModal from "../../../core/common/customModal";
import { useNotification } from "../../../core/hooks/useNotification";
import CommonSelect from "../../../core/common/commonSelect";
import { ConvertToStudentModal, UpdateStatusModal } from "./ApplicationModals";

interface Application {
  _id: string;
  applicationNo: string;
  admissionNo?: string;
  name: {
    english: string;
    tamil?: string;
  };
  status: "PENDING" | "APPROVED" | "REJECTED";
  classAppliedFor: string;
  admissionCycle?: {
    _id: string;
    name: string;
  };
  academicYear?: {
    _id: string;
    year: string;
  };
  father: {
    name: string;
    mobileNumber: string;
    occupation: string;
  };
  mother: {
    name: string;
    mobileNumber: string;
    occupation: string;
  };
  hasGuardian: boolean;
  guardian?: {
    name: string;
    mobileNumber: string;
    occupation: string;
    relation: string;
  };
  bloodGroup: string;
  dateOfBirth: string;
  religion: string;
  community: string;
  caste: string;
  motherTongue: string;
  nationality: string;
  state: string;
  address: string;
  identificationMarks: string;
  hasSiblings: boolean;
  siblings?: Array<{
    name: string;
    class: string;
    section: string;
    relation: string;
  }>;
  allergies: string[];
  medications: string[];
  previousSchool?: {
    name: string;
    periodOfStudy: string;
    class: string;
  };
  createdAt: string;
  reviewComments?: string;
  rejectionReason?: string;
}

interface AcademicYear {
  _id: string;
  year: string;
}

interface AdmissionCycle {
  _id: string;
  name: string;
  academicYear: AcademicYear;
  status: "DRAFT" | "ACTIVE" | "CLOSED";
}

interface FilterParams {
  page: number;
  limit: number;
  searchQuery?: string;
  status?: string;
  classAppliedFor?: string;
  startDate?: string;
  endDate?: string;
  admissionCycle?: string;
  academicYear?: string;
  sortBy?: string;
  sortOrder?: "asc" | "desc";
}

interface Option {
  label: string;
  value: string;
}

const Applications = () => {
  const routes = all_routes;
  const { AxiosGetWithToken, AxiosPostWithToken, AxiosPutWithToken } = useApi();
  const notification = useNotification();
  const dropdownMenuRef = useRef<HTMLDivElement | null>(null);

  // States
  const [applications, setApplications] = useState<Application[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedApplication, setSelectedApplication] =
    useState<Application | null>(null);

  const [pagination, setPagination] = useState({
    total: 0,
    page: 1,
    totalPages: 1,
    limit: 10,
  });

  const [filters, setFilters] = useState<FilterParams>({
    page: 1,
    limit: 10,
    sortBy: "createdAt",
    sortOrder: "desc",
  });

  // Filter states
  const [searchText, setSearchText] = useState("");
  const [selectedStatus, setSelectedStatus] = useState<string>("");
  const [selectedClass, setSelectedClass] = useState<string>("");
  const [academicYears, setAcademicYears] = useState<AcademicYear[]>([]);
  const [admissionCycles, setAdmissionCycles] = useState<AdmissionCycle[]>([]);
  const [selectedAcademicYear, setSelectedAcademicYear] = useState<string>("");
  const [selectedAdmissionCycle, setSelectedAdmissionCycle] =
    useState<string>("");
  const [dateRange, setDateRange] = useState({
    start: moment().subtract(29, "days"),
    end: moment(),
  });

  const [selectedApplicationDetails, setSelectedApplicationDetails] =
    useState<Application | null>(null);
  const [isLoadingDetails, setIsLoadingDetails] = useState(false);

  // Modal states
  const [modalConfig, setModalConfig] = useState<{
    id: string;
    modalSize?: string;
    show: boolean;
    title: string;
    action?: "VIEW" | "UPDATE_STATUS" | "CONVERT";
    status?: "APPROVED" | "REJECTED";
  }>({
    id: "",
    show: false,
    title: "",
  });

  useEffect(() => {
    fetchAcademicYears();
    fetchAdmissionCycles();
  }, []);

  useEffect(() => {
    fetchApplications();
  }, [filters]);

  useEffect(() => {
    // Initially fetch applications from active admission cycle
    const fetchInitialData = async () => {
      try {
        const activeAdmissionCycle = admissionCycles.find(
          (cycle) => cycle.status === "ACTIVE"
        );
        if (activeAdmissionCycle) {
          setSelectedAdmissionCycle(activeAdmissionCycle._id);
          setFilters((prev) => ({
            ...prev,
            admissionCycle: activeAdmissionCycle._id,
          }));
        }
      } catch (error) {
        console.error("Error fetching initial data:", error);
      }
    };

    if (admissionCycles.length > 0) {
      fetchInitialData();
    }
  }, [admissionCycles]);

  const fetchAcademicYears = async () => {
    try {
      const response = await AxiosGetWithToken("/academicYear");
      setAcademicYears(response);
    } catch (error) {
      notification.error("Failed to fetch academic years", {
        title: "Error",
        duration: 5000,
      });
    }
  };

  const fetchAdmissionCycles = async () => {
    try {
      const response = await AxiosGetWithToken("/admission-cycles");
      setAdmissionCycles(response);
    } catch (error) {
      notification.error("Failed to fetch admission cycles", {
        title: "Error",
        duration: 5000,
      });
    }
  };

  const fetchApplications = async () => {
    try {
      setIsLoading(true);
      const queryParams = new URLSearchParams();
      Object.entries(filters).forEach(([key, value]) => {
        if (value !== undefined && value !== "") {
          queryParams.append(key, value.toString());
        }
      });

      const response = await AxiosGetWithToken(
        `/applications?${queryParams.toString()}`
      );

      if (response) {
        setApplications(response.applications);
        setPagination({
          total: response.pagination.totalRecords,
          page: response.pagination.currentPage,
          totalPages: response.pagination.totalPages,
          limit: response.pagination.limit,
        });
      }
    } catch (error: any) {
      notification.error("Failed to fetch applications", {
        title: "Error",
        duration: 5000,
      });
      console.error("Failed to fetch applications:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchApplicationDetails = async (id: string) => {
    try {
      setIsLoadingDetails(true);
      const response = await AxiosGetWithToken(`/applications/${id}`);
      setSelectedApplicationDetails(response);
    } catch (error) {
      notification.error("Failed to fetch application details", {
        title: "Error",
        duration: 5000,
      });
    } finally {
      setIsLoadingDetails(false);
    }
  };

  const handleDateCallback = (start: moment.Moment, end: moment.Moment) => {
    setDateRange({ start, end });
    setFilters((prev) => ({
      ...prev,
      startDate: start.format("YYYY-MM-DD"),
      endDate: end.format("YYYY-MM-DD"),
    }));
  };

  const handleApplyFilters = () => {
    const newFilters: FilterParams = {
      ...filters,
      page: 1,
      searchQuery: searchText || undefined,
      status: selectedStatus || undefined,
      classAppliedFor: selectedClass || undefined,
      academicYear: selectedAcademicYear || undefined,
      admissionCycle: selectedAdmissionCycle || undefined,
    };

    // Remove undefined values
    Object.keys(newFilters).forEach((key) => {
      if (newFilters[key as keyof FilterParams] === undefined) {
        delete newFilters[key as keyof FilterParams];
      }
    });

    setFilters(newFilters);

    if (dropdownMenuRef.current) {
      dropdownMenuRef.current.classList.remove("show");
    }
  };

  const handleResetFilters = () => {
    setSearchText("");
    setSelectedStatus("");
    setSelectedClass("");
    setSelectedAcademicYear("");
    setSelectedAdmissionCycle("");
    setDateRange({
      start: moment().subtract(29, "days"),
      end: moment(),
    });
    setFilters({
      page: 1,
      limit: 10,
      sortBy: "createdAt",
      sortOrder: "desc",
    });
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    setFilters((prev) => ({
      ...prev,
      page: pagination.current,
      sortBy: sorter.field,
      sortOrder: sorter.order === "ascend" ? "asc" : "desc",
    }));
  };

  const getStatusBadgeClass = (status: string) => {
    switch (status) {
      case "APPROVED":
        return "success";
      case "REJECTED":
        return "danger";
      case "PENDING":
        return "warning";
      default:
        return "secondary";
    }
  };

  const handleViewDetails = async (record: Application) => {
    setSelectedApplication(record);
    setModalConfig({
      id: "viewDetailsModal",
      modalSize: "modal-lg",
      show: true,
      title: "Application Details",
      action: "VIEW",
    });

    await fetchApplicationDetails(record._id);

    const tempLink = document.createElement("a");
    tempLink.setAttribute("data-bs-toggle", "modal");
    tempLink.setAttribute("data-bs-target", "#viewDetailsModal");
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
  };

  const handleUpdateStatus = async (id: string, newStatus: string) => {
    setSelectedApplication(applications.find((app) => app._id === id) || null);
    setModalConfig({
      id: "updateStatusModal",
      show: true,
      title: `${newStatus} Application`,
      action: "UPDATE_STATUS",
      status: newStatus as "APPROVED" | "REJECTED",
    });

    const tempLink = document.createElement("a");
    tempLink.setAttribute("data-bs-toggle", "modal");
    tempLink.setAttribute("data-bs-target", "#updateStatusModal");
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
  };

  const handleConvertToStudent = async (id: string) => {
    setSelectedApplication(applications.find((app) => app._id === id) || null);
    setModalConfig({
      id: "convertModal",
      show: true,
      title: "Convert to Student",
      action: "CONVERT",
    });

    const tempLink = document.createElement("a");
    tempLink.setAttribute("data-bs-toggle", "modal");
    tempLink.setAttribute("data-bs-target", "#convertModal");
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
  };

  const submitStatusUpdate = async (id: string, status: string, data: any) => {
    try {
      await AxiosPutWithToken(
        `/applications/${id}/status`,
        {
          status,
          ...data,
        },
        true
      );

      notification.success(`Application ${status.toLowerCase()} successfully`, {
        title: "Success",
        duration: 5000,
      });

      fetchApplications();
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : "Failed to update status";
      notification.error(errorMessage, {
        title: "Error",
        duration: 5000,
      });
      throw error;
    }
  };

  const submitConversion = async (id: string, data: any) => {
    try {
      await AxiosPostWithToken(`/applications/${id}/convert`, data, true);

      notification.success("Application converted to student successfully", {
        title: "Success",
        duration: 5000,
      });

      fetchApplications();
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : "Failed to convert to student";
      notification.error(errorMessage, {
        title: "Error",
        duration: 5000,
      });
      throw error;
    }
  };

  const columns = [
    {
      title: "Application No",
      dataIndex: "applicationNo",
      sorter: true,
    },
    {
      title: "Student Name",
      dataIndex: ["name", "english"],
      sorter: true,
    },
    {
      title: "Class",
      dataIndex: "classAppliedFor",
      sorter: true,
    },
    {
      title: "Contact",
      render: (_: any, record: Application) => (
        <div>
          <div>{record.father.mobileNumber}</div>
          <div className="text-muted small">{record.father.name}</div>
        </div>
      ),
    },
    {
      title: "Academic Year",
      render: (_: any, record: Application) => (
        <div>
          <div>{record.academicYear?.year || "-"}</div>
          <div className="text-muted small">
            {record.admissionCycle?.name || "-"}
          </div>
        </div>
      ),
      sorter: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status: string) => (
        <span className={`badge badge-soft-${getStatusBadgeClass(status)}`}>
          {status}
        </span>
      ),
      sorter: true,
    },
    {
      title: "Applied Date",
      dataIndex: "createdAt",
      render: (date: string) => moment(date).format("DD MMM, YYYY"),
      sorter: true,
    },
    {
      title: "Actions",
      render: (_: any, record: Application) => (
        <div className="d-flex align-items-center">
          <button
            className="btn btn-outline-light bg-white btn-icon d-flex align-items-center justify-content-center rounded-circle p-0 me-2"
            onClick={() => handleViewDetails(record)}
            title="View Details"
          >
            <i className="ti ti-eye" />
          </button>

          {record.status === "PENDING" && (
            <>
              <button
                className="btn btn-outline-light bg-white btn-icon d-flex align-items-center justify-content-center rounded-circle p-0 me-2"
                onClick={() => handleUpdateStatus(record._id, "APPROVED")}
                title="Approve"
              >
                <i className="ti ti-check text-success" />
              </button>
              <button
                className="btn btn-outline-light bg-white btn-icon d-flex align-items-center justify-content-center rounded-circle p-0 me-2"
                onClick={() => handleUpdateStatus(record._id, "REJECTED")}
                title="Reject"
              >
                <i className="ti ti-x text-danger" />
              </button>
            </>
          )}

          {record.status === "APPROVED" && !record.admissionNo && (
            <button
              className="btn btn-outline-light bg-white btn-icon d-flex align-items-center justify-content-center rounded-circle p-0 me-2"
              onClick={() => handleConvertToStudent(record._id)}
              title="Convert to Student"
            >
              <i className="ti ti-user-plus text-primary" />
            </button>
          )}
        </div>
      ),
    },
  ];

  // Render view details modal content
  const renderViewContent = (application: Application) => {
    return (
      <div className="text-start">
        <div className="card border shadow-none mb-4">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <div className="card-title mb-1">
                  Application No: {application.applicationNo}
                </div>
                <div className="text-muted small">
                  Applied on:{" "}
                  {moment(application.createdAt).format("DD MMM, YYYY")}
                </div>
              </div>
              <span
                className={`badge badge-soft-${getStatusBadgeClass(
                  application.status
                )}`}
              >
                {application.status}
              </span>
            </div>
          </div>
        </div>

        <div className="card border shadow-none mb-4">
          <div className="card-header bg-transparent">
            <h5 className="card-title mb-0">
              <i className="ti ti-user me-2"></i>
              Basic Details
            </h5>
          </div>
          <div className="card-body">
            <div className="row g-3">
              <div className="col-md-6">
                <div className="text-muted small mb-1">Name (English)</div>
                <div className="fw-medium">{application.name.english}</div>
              </div>
              {application.name.tamil && (
                <div className="col-md-6">
                  <div className="text-muted small mb-1">Name (Tamil)</div>
                  <div className="fw-medium">{application.name.tamil}</div>
                </div>
              )}
              <div className="col-md-6">
                <div className="text-muted small mb-1">Date of Birth</div>
                <div className="fw-medium">
                  {moment(application.dateOfBirth).format("DD MMM, YYYY")}
                </div>
              </div>
              <div className="col-md-6">
                <div className="text-muted small mb-1">Blood Group</div>
                <div className="fw-medium">{application.bloodGroup}</div>
              </div>
              <div className="col-md-6">
                <div className="text-muted small mb-1">Class Applied For</div>
                <div className="fw-medium">{application.classAppliedFor}</div>
              </div>
              <div className="col-md-6">
                <div className="text-muted small mb-1">Mother Tongue</div>
                <div className="fw-medium">{application.motherTongue}</div>
              </div>
              <div className="col-md-4">
                <div className="text-muted small mb-1">Religion</div>
                <div className="fw-medium">{application.religion}</div>
              </div>
              <div className="col-md-4">
                <div className="text-muted small mb-1">Community</div>
                <div className="fw-medium">{application.community}</div>
              </div>
              <div className="col-md-4">
                <div className="text-muted small mb-1">Caste</div>
                <div className="fw-medium">{application.caste}</div>
              </div>
            </div>
          </div>
        </div>

        <div className="card border shadow-none mb-4">
          <div className="card-header bg-transparent">
            <h5 className="card-title mb-0">
              <i className="ti ti-users me-2"></i>
              Family Details
            </h5>
          </div>
          <div className="card-body">
            <div className="row g-4">
              <div className="col-md-6">
                <div className="border rounded p-3">
                  <h6 className="mb-3">Father's Details</h6>
                  <div className="mb-2">
                    <div className="text-muted small">Name</div>
                    <div className="fw-medium">{application.father.name}</div>
                  </div>
                  <div className="mb-2">
                    <div className="text-muted small">Mobile</div>
                    <div className="fw-medium">
                      {application.father.mobileNumber}
                    </div>
                  </div>
                  <div>
                    <div className="text-muted small">Occupation</div>
                    <div className="fw-medium">
                      {application.father.occupation}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="border rounded p-3">
                  <h6 className="mb-3">Mother's Details</h6>
                  <div className="mb-2">
                    <div className="text-muted small">Name</div>
                    <div className="fw-medium">{application.mother.name}</div>
                  </div>
                  <div className="mb-2">
                    <div className="text-muted small">Mobile</div>
                    <div className="fw-medium">
                      {application.mother.mobileNumber}
                    </div>
                  </div>
                  <div>
                    <div className="text-muted small">Occupation</div>
                    <div className="fw-medium">
                      {application.mother.occupation}
                    </div>
                  </div>
                </div>
              </div>
              {application.hasGuardian && application.guardian && (
                <div className="col-12">
                  <div className="border rounded p-3">
                    <h6 className="mb-3">Guardian's Details</h6>
                    <div className="row g-3">
                      <div className="col-md-6">
                        <div className="text-muted small">Name</div>
                        <div className="fw-medium">
                          {application.guardian.name}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="text-muted small">Relation</div>
                        <div className="fw-medium">
                          {application.guardian.relation}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="text-muted small">Mobile</div>
                        <div className="fw-medium">
                          {application.guardian.mobileNumber}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="text-muted small">Occupation</div>
                        <div className="fw-medium">
                          {application.guardian.occupation}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {application.hasSiblings &&
          application.siblings &&
          application.siblings.length > 0 && (
            <div className="card border shadow-none mb-4">
              <div className="card-header bg-transparent">
                <h5 className="card-title mb-0">
                  <i className="ti ti-users me-2"></i>
                  Siblings Information
                </h5>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-bordered m-0">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Class</th>
                        <th>Section</th>
                        <th>Relation</th>
                      </tr>
                    </thead>
                    <tbody>
                      {application.siblings.map((sibling, index) => (
                        <tr key={index}>
                          <td>{sibling.name}</td>
                          <td>{sibling.class}</td>
                          <td>{sibling.section}</td>
                          <td>{sibling.relation}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}

        <div className="card border shadow-none mb-4">
          <div className="card-header bg-transparent">
            <h5 className="card-title mb-0">
              <i className="ti ti-home me-2"></i>
              Address & Contact
            </h5>
          </div>
          <div className="card-body">
            <div className="row g-3">
              <div className="col-12">
                <div className="text-muted small mb-1">Address</div>
                <div className="fw-medium">{application.address}</div>
              </div>
              <div className="col-md-6">
                <div className="text-muted small mb-1">Nationality</div>
                <div className="fw-medium">{application.nationality}</div>
              </div>
              <div className="col-md-6">
                <div className="text-muted small mb-1">State</div>
                <div className="fw-medium">{application.state}</div>
              </div>
            </div>
          </div>
        </div>

        {(application.allergies?.length > 0 ||
          application.medications?.length > 0) && (
          <div className="card border shadow-none mb-4">
            <div className="card-header bg-transparent">
              <h5 className="card-title mb-0">
                <i className="ti ti-medical-cross me-2"></i>
                Medical Information
              </h5>
            </div>
            <div className="card-body">
              <div className="row g-3">
                {application.allergies?.length > 0 && (
                  <div className="col-md-6">
                    <div className="text-muted small mb-1">Allergies</div>
                    <div className="fw-medium">
                      {application.allergies.join(", ")}
                    </div>
                  </div>
                )}
                {application.medications?.length > 0 && (
                  <div className="col-md-6">
                    <div className="text-muted small mb-1">Medications</div>
                    <div className="fw-medium">
                      {application.medications.join(", ")}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {application.previousSchool && (
          <div className="card border shadow-none mb-4">
            <div className="card-header bg-transparent">
              <h5 className="card-title mb-0">
                <i className="ti ti-school me-2"></i>
                Previous School Details
              </h5>
            </div>
            <div className="card-body">
              <div className="row g-3">
                <div className="col-md-6">
                  <div className="text-muted small mb-1">School Name</div>
                  <div className="fw-medium">
                    {application.previousSchool.name}
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="text-muted small mb-1">Class</div>
                  <div className="fw-medium">
                    {application.previousSchool.class}
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="text-muted small mb-1">Period of Study</div>
                  <div className="fw-medium">
                    {application.previousSchool.periodOfStudy}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {(application.status === "APPROVED" ||
          application.status === "REJECTED") && (
          <div className="card border shadow-none">
            <div className="card-header bg-transparent">
              <h5 className="card-title mb-0">
                <i className="ti ti-note me-2"></i>
                Review Details
              </h5>
            </div>
            <div className="card-body">
              {application.reviewComments && (
                <div className="mb-3">
                  <div className="text-muted small mb-1">Comments</div>
                  <div className="fw-medium">{application.reviewComments}</div>
                </div>
              )}
              {application.status === "REJECTED" &&
                application.rejectionReason && (
                  <div>
                    <div className="text-muted small mb-1">
                      Rejection Reason
                    </div>
                    <div className="fw-medium">
                      {application.rejectionReason}
                    </div>
                  </div>
                )}
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        {/* Page Header */}
        <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
          <div className="my-auto mb-2">
            <h3 className="page-title mb-1">Applications</h3>
            <nav>
              <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to={routes.adminDashboard}>Dashboard</Link>
                </li>
                <li className="breadcrumb-item active">Applications</li>
              </ol>
            </nav>
          </div>

          {/* Quick Stats */}
          <div className="d-flex gap-3 my-xl-auto right-content align-items-center">
            <div className="card border bg-white shadow-none mb-0">
              <div className="card-body text-center p-2 p-md-3">
                <h6 className="mb-1">
                  {
                    applications.filter((app) => app.status === "PENDING")
                      .length
                  }
                </h6>
                <p className="text-muted small mb-0">Pending</p>
              </div>
            </div>
            <div className="card border bg-white shadow-none mb-0">
              <div className="card-body text-center p-2 p-md-3">
                <h6 className="mb-1">
                  {
                    applications.filter((app) => app.status === "APPROVED")
                      .length
                  }
                </h6>
                <p className="text-muted small mb-0">Approved</p>
              </div>
            </div>
            <div className="card border bg-white shadow-none mb-0">
              <div className="card-body text-center p-2 p-md-3">
                <h6 className="mb-1">
                  {
                    applications.filter((app) => app.status === "REJECTED")
                      .length
                  }
                </h6>
                <p className="text-muted small mb-0">Rejected</p>
              </div>
            </div>
          </div>
        </div>

        {/* Applications List Card */}
        <div className="card">
          <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
            <h4 className="mb-3">Applications List</h4>
            <div className="d-flex align-items-center flex-wrap">
              {/* Date Range Picker */}
              <div className="input-icon-start mb-3 me-2 position-relative">
                <DateRangePicker
                  initialSettings={{
                    startDate: dateRange.start.toDate(),
                    endDate: dateRange.end.toDate(),
                    ranges: {
                      Today: [moment().toDate(), moment().toDate()],
                      Yesterday: [
                        moment().subtract(1, "days").toDate(),
                        moment().subtract(1, "days").toDate(),
                      ],
                      "Last 7 Days": [
                        moment().subtract(6, "days").toDate(),
                        moment().toDate(),
                      ],
                      "Last 30 Days": [
                        moment().subtract(29, "days").toDate(),
                        moment().toDate(),
                      ],
                      "This Month": [
                        moment().startOf("month").toDate(),
                        moment().endOf("month").toDate(),
                      ],
                      "Last Month": [
                        moment().subtract(1, "month").startOf("month").toDate(),
                        moment().subtract(1, "month").endOf("month").toDate(),
                      ],
                    },
                  }}
                  onCallback={handleDateCallback}
                >
                  <div
                    className="col-4"
                    style={{
                      background: "#fff",
                      cursor: "pointer",
                      padding: "0.5rem 0.625rem",
                      border: "1px solid #E9EDF4",
                      width: "100%",
                      borderRadius: "5px",
                      fontSize: "14px",
                      color: "#202C4B",
                      height: "38px",
                    }}
                  >
                    <i className="ti ti-calendar"></i>&nbsp;
                    <span>{`${dateRange.start.format(
                      "D MMMM, YYYY"
                    )} - ${dateRange.end.format("D MMMM, YYYY")}`}</span>
                  </div>
                </DateRangePicker>
              </div>

              {/* Filter Dropdown */}
              <div className="dropdown mb-3 me-2">
                <Link
                  to="#"
                  className="btn btn-outline-light bg-white dropdown-toggle"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="outside"
                >
                  <i className="ti ti-filter me-2" />
                  Filter
                </Link>
                <div className="dropdown-menu drop-width" ref={dropdownMenuRef}>
                  <form>
                    <div className="p-3 border-bottom">
                      <div className="row g-3">
                        <div className="col-md-12">
                          <div className="mb-3">
                            <label className="form-label">Academic Year</label>
                            <CommonSelect
                              className="select"
                              options={academicYears.map((year) => ({
                                label: year.year,
                                value: year._id,
                              }))}
                              value={selectedAcademicYear}
                              onChange={(option) => {
                                setSelectedAcademicYear(option?.value || "");
                                // Clear admission cycle when academic year changes
                                setSelectedAdmissionCycle("");
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="mb-3">
                            <label className="form-label">
                              Admission Cycle
                            </label>
                            <CommonSelect
                              className="select"
                              options={admissionCycles
                                .filter(
                                  (cycle) =>
                                    !selectedAcademicYear ||
                                    cycle.academicYear._id ===
                                      selectedAcademicYear
                                )
                                .map((cycle) => ({
                                  label:
                                    cycle.name ||
                                    `Cycle (${cycle.academicYear.year})`,
                                  value: cycle._id,
                                }))}
                              value={selectedAdmissionCycle}
                              onChange={(option) =>
                                setSelectedAdmissionCycle(option?.value || "")
                              }
                            />
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="mb-3">
                            <label className="form-label">Search Text</label>
                            <input
                              type="text"
                              className="form-control"
                              value={searchText}
                              onChange={(e) => setSearchText(e.target.value)}
                              placeholder="Search by name, application no, or mobile"
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Status</label>
                            <select
                              className="form-select"
                              value={selectedStatus}
                              onChange={(e) =>
                                setSelectedStatus(e.target.value)
                              }
                            >
                              <option value="">All</option>
                              <option value="PENDING">Pending</option>
                              <option value="APPROVED">Approved</option>
                              <option value="REJECTED">Rejected</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Class</label>
                            <input
                              type="text"
                              className="form-control"
                              value={selectedClass}
                              onChange={(e) => setSelectedClass(e.target.value)}
                              placeholder="Enter class"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="p-3 d-flex align-items-center justify-content-end">
                      <button
                        type="button"
                        className="btn btn-light me-3"
                        onClick={handleResetFilters}
                      >
                        Reset
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleApplyFilters}
                      >
                        Apply
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          {/* Table Section */}
          <div className="card-body p-0 py-3">
            <Table
              dataSource={applications}
              columns={columns}
              Selection={false}
              pagination={{
                total: pagination.total,
                current: pagination.page,
                pageSize: pagination.limit,
                locale: { items_per_page: "" },
                nextIcon: <span>Next</span>,
                prevIcon: <span>Prev</span>,
              }}
              onChange={handleTableChange}
            />
          </div>
        </div>
      </div>

      {/* View Details Modal */}
      <CustomModal
        id="viewDetailsModal"
        modalSize={modalConfig.modalSize}
        onClose={() => {
          setModalConfig((prev) => ({ ...prev, show: false }));
          setSelectedApplicationDetails(null);
        }}
        title={modalConfig.title}
      >
        {isLoadingDetails ? (
          <div className="text-center p-4">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : selectedApplicationDetails ? (
          renderViewContent(selectedApplicationDetails)
        ) : (
          <div className="text-center p-4">
            <p className="text-muted">No application details available</p>
          </div>
        )}
      </CustomModal>

      {/* Update Status Modal */}
      <CustomModal
        id="updateStatusModal"
        onClose={() => setModalConfig((prev) => ({ ...prev, show: false }))}
        title={modalConfig.title}
      >
        {selectedApplication && modalConfig.status && (
          <UpdateStatusModal
            applicationId={selectedApplication._id}
            status={modalConfig.status}
            onClose={() => setModalConfig((prev) => ({ ...prev, show: false }))}
            onUpdateStatus={submitStatusUpdate}
          />
        )}
      </CustomModal>

      {/* Convert to Student Modal */}
      <CustomModal
        id="convertModal"
        onClose={() => setModalConfig((prev) => ({ ...prev, show: false }))}
        title={modalConfig.title}
      >
        {selectedApplication && (
          <ConvertToStudentModal
            applicationId={selectedApplication._id}
            onClose={() => setModalConfig((prev) => ({ ...prev, show: false }))}
            onConvert={submitConversion}
          />
        )}
      </CustomModal>
    </div>
  );
};

export default Applications;
