import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { useApi } from "../../../utils/api";
import { all_routes } from "../../router/all_routes";
import Table from "../../../core/common/dataTable";

import ImageWithBasePath from "../../../core/common/imageWithBasePath";
import TooltipOption from "../../../core/common/tooltipOption";
import { DateRangePicker } from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import ViewFeeModal from "../../peoples/students/ViewFee";
import StudentModals from "../../peoples/students/studentModals";

declare const bootstrap: any;

// Types and Interfaces
interface ExportFilters {
  class?: string;
  section?: string;
  name?: string;
  rollNo?: string;
  startDate?: string;
  endDate?: string;
}

interface Student {
  studentId: string;
  name: string;
  image: string;
  class: string;
  section: string;
  rollNo: string;
}

interface Payment {
  mode: string;
  details: string;
}

interface FeeEntry {
  _id: string;
  serialNumber: string;
  date: string;
  student: Student;
  totalAmount: number;
  formattedAmount: string;
  status: string;
  payment: Payment;
}

interface FilterParams {
  page: number;
  limit: number;
  searchQuery?: string;
  class?: string;
  section?: string;
  startDate?: string;
  endDate?: string;
  status?: string;
  minAmount?: number;
  maxAmount?: number;
  sortBy?: string;
  order?: "asc" | "desc";
}

interface PaginationData {
  total: number;
  page: number;
  totalPages: number;
  limit: number;
}

const CollectFees = () => {
  const routes = all_routes;
  const { AxiosGetWithToken } = useApi();
  const dropdownMenuRef = useRef<HTMLDivElement | null>(null);

  // States
  const [fees, setFees] = useState<FeeEntry[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFeeId, setSelectedFeeId] = useState<string | null>(null);
  const [pagination, setPagination] = useState<PaginationData>({
    total: 0,
    page: 1,
    totalPages: 1,
    limit: 10,
  });

  // Filter states
  const [filters, setFilters] = useState<FilterParams>({
    page: 1,
    limit: 10,
    order: "desc",
    sortBy: "createdAt",
  });

  // Form states
  const [searchText, setSearchText] = useState("");
  const [classInput, setClassInput] = useState("");
  const [sectionInput, setSectionInput] = useState("");
  const [amountRange, setAmountRange] = useState<{
    min?: string;
    max?: string;
  }>({});
  const [dateRange, setDateRange] = useState({
    start: moment().subtract(29, "days"),
    end: moment(),
  });

  useEffect(() => {
    fetchFees();
  }, [filters]);

  const fetchFees = async () => {
    try {
      setIsLoading(true);
      const queryParams = new URLSearchParams();

      // Add all filters to query params
      Object.entries(filters).forEach(([key, value]) => {
        if (value !== undefined && value !== "") {
          queryParams.append(key, value.toString());
        }
      });

      const response = await AxiosGetWithToken(
        `/fees?${queryParams.toString()}`
      );

      if (response) {
        setFees(response.fees);
        setPagination({
          total: response.total,
          page: response.page,
          totalPages: response.totalPages,
          limit: response.limit,
        });
      }
    } catch (error) {
      console.error("Failed to fetch fees:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDateCallback = (start: moment.Moment, end: moment.Moment) => {
    setDateRange({ start, end });
    setFilters((prev) => ({
      ...prev,
      startDate: start.format("YYYY-MM-DD"),
      endDate: end.format("YYYY-MM-DD"),
    }));
  };

  const handleApplyFilters = () => {
    const newFilters: FilterParams = {
      ...filters,
      page: 1,
      searchQuery: searchText || undefined,
      class: classInput || undefined,
      section: sectionInput || undefined,
      minAmount: amountRange.min ? Number(amountRange.min) : undefined,
      maxAmount: amountRange.max ? Number(amountRange.max) : undefined,
    };

    // Remove undefined values
    Object.keys(newFilters).forEach((key) => {
      if (newFilters[key as keyof FilterParams] === undefined) {
        delete newFilters[key as keyof FilterParams];
      }
    });

    setFilters(newFilters);

    if (dropdownMenuRef.current) {
      dropdownMenuRef.current.classList.remove("show");
    }
  };

  const handleResetFilters = () => {
    setSearchText("");
    setClassInput("");
    setSectionInput("");
    setAmountRange({});
    setDateRange({
      start: moment().subtract(29, "days"),
      end: moment(),
    });
    setFilters({
      page: 1,
      limit: 10,
      order: "desc",
      sortBy: "createdAt",
    });
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    setFilters((prev) => ({
      ...prev,
      page: pagination.current,
      sortBy: sorter.field,
      order: sorter.order === "ascend" ? "asc" : "desc",
    }));
  };

  const columns = [
    {
      title: "Invoice No",
      dataIndex: "serialNumber",
      render: (text: string, record: FeeEntry) => (
        <Link
          to="#"
          className="link-primary"
          data-bs-toggle="modal"
          data-bs-target="#view_fee_details"
          onClick={() => setSelectedFeeId(record._id)}
        >
          {text}
        </Link>
      ),
      sorter: true,
    },
    {
      title: "Student Name",
      dataIndex: ["student", "name"],
      render: (_: string, record: FeeEntry) => (
        <Link to={routes.studentDetail}>{record.student.name}</Link>
      ),
      sorter: true,
    },
    {
      title: "Roll No",
      dataIndex: ["student", "rollNo"],
      sorter: true,
    },
    {
      title: "Class",
      dataIndex: ["student", "class"],
      sorter: true,
    },
    {
      title: "Section",
      dataIndex: ["student", "section"],
      sorter: true,
    },
    {
      title: "Amount",
      dataIndex: "totalAmount",
      render: (amount: number) => (
        <span>
          ₹{amount.toLocaleString("en-IN", { maximumFractionDigits: 2 })}
        </span>
      ),
      sorter: true,
    },
    {
      title: "Date",
      dataIndex: "date",
      render: (date: string) => (
        <span>{moment(date).format("DD MMM, YYYY")}</span>
      ),
      sorter: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text: string) => (
        <span
          className={`badge badge-soft-${
            text === "active" ? "success" : "danger"
          } d-inline-flex align-items-center`}
        >
          {text}
        </span>
      ),
      sorter: true,
    },
    {
      title: "Fee Receipt",
      render: (_: any, record: FeeEntry) => (
        <Link
          to="#"
          className="btn btn-light"
          data-bs-toggle="modal"
          data-bs-target="#view_fee_details"
          onClick={() => setSelectedFeeId(record._id)}
        >
          View Details
        </Link>
      ),
    },
  ];

  const handleRefresh = () => {
    fetchFees();
  };

  const handleExport = async (filters: ExportFilters) => {
    let url: string | null = null;
    let downloadLink: HTMLAnchorElement | null = null;

    const cleanup = () => {
      if (downloadLink && document.body.contains(downloadLink)) {
        document.body.removeChild(downloadLink);
      }
      if (url !== null) {
        // Add a small delay before revoking the URL to ensure download starts
        setTimeout(() => {
          if (url) {
            // additional check in case url becomes null during timeout
            window.URL.revokeObjectURL(url);
          }
        }, 1000);
      }
    };

    try {
      // Fetch data
      const queryParams = new URLSearchParams();
      Object.entries(filters).forEach(([key, value]) => {
        if (value) queryParams.append(key, value.toString());
      });
      queryParams.append("limit", "9999");

      const response = await AxiosGetWithToken(
        `/fees?${queryParams.toString()}`
      );

      if (!response?.fees?.length) {
        alert("No data found for export");
        return;
      }

      // Process data
      const excelData = response.fees.map((fee: FeeEntry) => ({
        "Invoice No": fee.serialNumber,
        "Student Name": fee.student.name,
        "Roll No": fee.student.rollNo,
        Class: fee.student.class,
        Section: fee.student.section,
        Amount: fee.totalAmount,
        Date: moment(fee.date).format("DD MMM, YYYY"),
        Status: fee.status,
      }));

      // Generate Excel file
      const XLSX = await import("xlsx");
      const worksheet = XLSX.utils.json_to_sheet(excelData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Fees Data");

      // Create blob and URL
      const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
      const blob = new Blob([wbout], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Create and trigger download
      url = window.URL.createObjectURL(blob);
      downloadLink = document.createElement("a");
      downloadLink.href = url;
      downloadLink.download = `fees_export_${moment().format(
        "YYYY-MM-DD_HH-mm-ss"
      )}.xlsx`;
      downloadLink.style.display = "none";
      document.body.appendChild(downloadLink);

      // Use a promise to handle the download
      await new Promise<void>((resolve, reject) => {
        if (!downloadLink) {
          reject(new Error("Download link not created"));
          return;
        }

        downloadLink.onclick = () => {
          setTimeout(resolve, 1000);
        };

        downloadLink.onerror = () => {
          reject(new Error("Download failed"));
        };

        downloadLink.click();

        // Fallback resolution in case click event doesn't fire
        setTimeout(resolve, 2000);
      });

      // Close the modal
      const modalEl = document.getElementById("export_modal");
      const modalDismissButton = modalEl?.querySelector(
        '[data-bs-dismiss="modal"]'
      ) as HTMLElement;
      modalDismissButton?.click();
    } catch (error: any) {
      console.error("Export failed:", error);
      alert("Export failed. Please try again.");
    } finally {
      cleanup();
    }
  };

  const handleAddFees = () => {
    // This will be implemented later
    console.log("Add fees clicked");
    const tempLink = document.createElement("a");
    tempLink.setAttribute("data-bs-toggle", "modal");
    tempLink.setAttribute("data-bs-target", "#add_fees_collect");

    // Add to DOM temporarily
    document.body.appendChild(tempLink);

    // Click it
    tempLink.click();

    // Remove it
    document.body.removeChild(tempLink);
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          {/* Page Header */}
          <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
            <div className="my-auto mb-2">
              <h3 className="page-title mb-1">Fees Collection</h3>
              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to={routes.adminDashboard}>Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Fees Collection</li>
                </ol>
              </nav>
            </div>
            <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
              <TooltipOption
                showAddButton={true}
                addButtonConfig={{
                  show: true,
                  text: "New Fees",
                  icon: "ti ti-plus",
                  onClick: handleAddFees,
                }}
                exportConfig={{
                  show: false,
                  // useExportModal: true,
                  // onExport: handleExport,
                  // modalTitle: "Export Fees Data",
                  // text: "Export",
                  // icon: "ti ti-file-export",
                  // options: [
                  //   {
                  //     label: "Export as Excel",
                  //     icon: "ti ti-file-type-xls",
                  //   },
                  // ],
                }}
                onRefresh={handleRefresh}
                exportModalConfig={{
                  showClassSection: true,
                  showNameRoll: true,
                  showDateRange: true,
                }}
              />
            </div>
          </div>

          {/* Fees List Card */}
          <div className="card">
            <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
              <h4 className="mb-3">Fees List</h4>
              <div className="d-flex align-items-center flex-wrap">
                {/* Date Range Picker */}
                <div className="input-icon-start mb-3 me-2 position-relative">
                  <DateRangePicker
                    initialSettings={{
                      startDate: dateRange.start.toDate(),
                      endDate: dateRange.end.toDate(),
                      ranges: {
                        Today: [moment().toDate(), moment().toDate()],
                        Yesterday: [
                          moment().subtract(1, "days").toDate(),
                          moment().subtract(1, "days").toDate(),
                        ],
                        "Last 7 Days": [
                          moment().subtract(6, "days").toDate(),
                          moment().toDate(),
                        ],
                        "Last 30 Days": [
                          moment().subtract(29, "days").toDate(),
                          moment().toDate(),
                        ],
                        "This Month": [
                          moment().startOf("month").toDate(),
                          moment().endOf("month").toDate(),
                        ],
                        "Last Month": [
                          moment()
                            .subtract(1, "month")
                            .startOf("month")
                            .toDate(),
                          moment().subtract(1, "month").endOf("month").toDate(),
                        ],
                      },
                    }}
                    onCallback={handleDateCallback}
                  >
                    <div
                      className="col-4"
                      style={{
                        background: "#fff",
                        cursor: "pointer",
                        padding: "0.5rem 0.625rem",
                        border: "1px solid #E9EDF4",
                        width: "100%",
                        borderRadius: "5px",
                        fontSize: "14px",
                        color: "#202C4B",
                        height: "38px",
                      }}
                    >
                      <i className="ti ti-calendar"></i>&nbsp;
                      <span>{`${dateRange.start.format(
                        "D MMMM, YYYY"
                      )} - ${dateRange.end.format("D MMMM, YYYY")}`}</span>
                    </div>
                  </DateRangePicker>
                </div>

                {/* Filter Dropdown */}
                <div className="dropdown mb-3 me-2">
                  <Link
                    to="#"
                    className="btn btn-outline-light bg-white dropdown-toggle"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                  >
                    <i className="ti ti-filter me-2" />
                    Filter
                  </Link>
                  <div
                    className="dropdown-menu drop-width"
                    ref={dropdownMenuRef}
                  >
                    <form>
                      <div className="p-3 border-bottom">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="mb-3">
                              <label className="form-label">Search Text</label>
                              <input
                                type="text"
                                className="form-control"
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                                placeholder="Search by name, roll no, or invoice no"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Class</label>
                              <input
                                type="text"
                                className="form-control"
                                value={classInput}
                                onChange={(e) => setClassInput(e.target.value)}
                                placeholder="Enter class"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Section</label>
                              <input
                                type="text"
                                className="form-control"
                                value={sectionInput}
                                onChange={(e) =>
                                  setSectionInput(e.target.value)
                                }
                                placeholder="Enter section"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Min Amount</label>
                              <input
                                type="number"
                                className="form-control"
                                value={amountRange.min || ""}
                                onChange={(e) =>
                                  setAmountRange((prev) => ({
                                    ...prev,
                                    min: e.target.value,
                                  }))
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Max Amount</label>
                              <input
                                type="number"
                                className="form-control"
                                value={amountRange.max || ""}
                                onChange={(e) =>
                                  setAmountRange((prev) => ({
                                    ...prev,
                                    max: e.target.value,
                                  }))
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="p-3 d-flex align-items-center justify-content-end">
                        <button
                          type="button"
                          className="btn btn-light me-3"
                          onClick={handleResetFilters}
                        >
                          Reset
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={handleApplyFilters}
                        >
                          Apply
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                {/* Sort Dropdown */}
                <div className="dropdown mb-3">
                  <Link
                    to="#"
                    className="btn btn-outline-light bg-white dropdown-toggle"
                    data-bs-toggle="dropdown"
                  >
                    <i className="ti ti-sort-ascending-2 me-2" />
                    Sort by: {filters.sortBy} ({filters.order})
                  </Link>
                  <ul className="dropdown-menu p-3">
                    <li>
                      <Link
                        to="#"
                        className="dropdown-item rounded-1"
                        onClick={() =>
                          setFilters((prev) => ({ ...prev, order: "asc" }))
                        }
                      >
                        Ascending
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        className="dropdown-item rounded-1"
                        onClick={() =>
                          setFilters((prev) => ({ ...prev, order: "desc" }))
                        }
                      >
                        Descending
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        className="dropdown-item rounded-1"
                        onClick={() =>
                          setFilters((prev) => ({
                            ...prev,
                            sortBy: "createdAt",
                          }))
                        }
                      >
                        Recently Added
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        className="dropdown-item rounded-1"
                        onClick={() =>
                          setFilters((prev) => ({ ...prev, sortBy: "date" }))
                        }
                      >
                        By Date
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* Table Section */}
            <div className="card-body p-0 py-3">
              <Table
                dataSource={fees}
                columns={columns}
                Selection={false}
                pagination={{
                  total: pagination.total,
                  current: pagination.page,
                  pageSize: pagination.limit,
                  locale: { items_per_page: "" },
                  nextIcon: <span>Next</span>,
                  prevIcon: <span>Prev</span>,
                }}
                onChange={handleTableChange}
              />
            </div>
          </div>
        </div>
      </div>

      {/* /Page Wrapper */}
      <StudentModals />

      {/* View Fee Modal */}
      <ViewFeeModal feeId={selectedFeeId} />
    </>
  );
};

export default CollectFees;
