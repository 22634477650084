import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../data/redux/store";
import {
  addNotification,
  removeNotification,
  setMaxVisible,
  NotificationType,
} from "../data/redux/notificationSlice";

interface NotificationOptions {
  title?: string;
  duration?: number;
}

export const useNotification = () => {
  const dispatch = useAppDispatch();

  const showNotification = useCallback(
    (
      type: NotificationType,
      message: string,
      options?: NotificationOptions
    ) => {
      const payload = {
        type,
        message,
        title: options?.title,
        duration: options?.duration || 5000,
      };

      dispatch(addNotification(payload));

      // Auto remove notification after duration
      if (payload.duration) {
        setTimeout(() => {
          dispatch(removeNotification(Date.now().toString()));
        }, payload.duration);
      }
    },
    [dispatch]
  );

  const closeNotification = useCallback(
    (id: string) => {
      dispatch(removeNotification(id));
    },
    [dispatch]
  );

  const updateMaxVisible = useCallback(
    (max: number) => {
      dispatch(setMaxVisible(max));
    },
    [dispatch]
  );

  return {
    success: (message: string, options?: NotificationOptions) =>
      showNotification("success", message, options),
    error: (message: string, options?: NotificationOptions) =>
      showNotification("error", message, options),
    warning: (message: string, options?: NotificationOptions) =>
      showNotification("warning", message, options),
    info: (message: string, options?: NotificationOptions) =>
      showNotification("info", message, options),
    close: closeNotification,
    setMaxVisible: updateMaxVisible,
  };
};

export const useNotificationState = () => {
  return useAppSelector((state) => state.notification);
};
