import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import dayjs from "dayjs";
import { DatePicker } from "antd";
import { useApi } from "../../../utils/api";
import { all_routes } from "../../router/all_routes";
import Table from "../../../core/common/dataTable";
import TooltipOption from "../../../core/common/tooltipOption";
import CustomModal from "../../../core/common/customModal";
import { render } from "@react-pdf/renderer";
import CommonSelect from "../../../core/common/commonSelect";
import { ActivateModalContent } from "./ActivateCycleModal";
import { CloseModalContent } from "./CloseCycleModal";

interface AdmissionCycle {
  _id: string;
  name?: string;
  academicYear: {
    _id: string;
    year: string;
  };
  status: "DRAFT" | "ACTIVE" | "CLOSED";
  startDate: string;
  endDate: string;
  totalApplications: number;
  createdAt: string;
  updatedAt: string;
  createdBy: {
    _id: string;
    username: string;
  };
  updatedBy: {
    _id: string;
    username: string;
  };
  publicUrl?: string;
  applicationNumberFormat: string;
  admissionNumberFormat: string;
  applicationCounterStart: number;
  admissionCounterStart: number;
}

interface FilterParams {
  page: number;
  limit: number;
  searchQuery?: string;
  status?: string;
  startDate?: string;
  endDate?: string;
  sortBy?: string;
  order?: "asc" | "desc";
}

interface EditFormData {
  name: string;
  academicYear: string;
  startDate: string;
  endDate: string;
  applicationNumberFormat: string;
  applicationCounterStart: number;
  admissionNumberFormat: string;
  admissionCounterStart: number;
}

interface CreateCycleFormData {
  name: string;
  academicYear: string;
  startDate: string;
  endDate: string;
  applicationNumberFormat: string;
  applicationCounterStart: number;
  admissionNumberFormat: string;
  admissionCounterStart: number;
}

interface Option {
  label: string;
  value: string;
}

const AdmissionCycles = () => {
  const routes = all_routes;
  const { AxiosGetWithToken, AxiosPostWithToken, AxiosPutWithToken } = useApi();

  const [cycles, setCycles] = useState<AdmissionCycle[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editFormData, setEditFormData] = useState<EditFormData | null>(null);

  const [pagination, setPagination] = useState({
    total: 0,
    page: 1,
    totalPages: 1,
    limit: 10,
  });

  const [filters, setFilters] = useState<FilterParams>({
    page: 1,
    limit: 10,
    order: "desc",
    sortBy: "createdAt",
  });

  const [modalConfig, setModalConfig] = useState<{
    id: string;
    modalSize?: string;
    show: boolean;
    title: string;
    showQR?: boolean;
    showLink?: boolean;
    qrValue?: string;
    linkValue?: string;
    content?: React.ReactNode;
  }>({
    id: "",
    show: false,
    title: "",
  });

  const [selectedRecord, setSelectedRecord] = useState<AdmissionCycle>({
    _id: "",
    name: "",
    academicYear: {
      _id: "",
      year: "",
    },
    status: "DRAFT",
    startDate: "",
    endDate: "",
    totalApplications: 0,
    createdAt: "",
    updatedAt: "",
    createdBy: {
      _id: "",
      username: "",
    },
    updatedBy: {
      _id: "",
      username: "",
    },
    applicationNumberFormat: "",
    admissionNumberFormat: "",
    applicationCounterStart: 0,
    admissionCounterStart: 0,
    publicUrl: "",
  });

  const [academicYears, setAcademicYears] = useState<
    Array<{
      _id: string;
      year: string;
    }>
  >([]);

  const [createFormData, setCreateFormData] = useState<CreateCycleFormData>({
    name: "",
    academicYear: "",
    startDate: "",
    endDate: "",
    applicationNumberFormat: "APP/{YEAR}/{COUNTER}",
    applicationCounterStart: 1,
    admissionNumberFormat: "ADM/{YEAR}/{COUNTER}",
    admissionCounterStart: 1,
  });

  const fetchAcademicYears = async () => {
    try {
      const academicYearsData = await AxiosGetWithToken("/academicYear");
      setAcademicYears(academicYearsData);
    } catch (error) {
      console.error("Failed to fetch academic years:", error);
    }
  };

  const handleCreateSubmit = async () => {
    try {
      await AxiosPostWithToken("/admission-cycles", createFormData, true);

      // Close modal
      const modalEl = document.getElementById("createCycleModal");
      const modalDismissButton = modalEl?.querySelector(
        '[data-bs-dismiss="modal"]'
      ) as HTMLElement;
      modalDismissButton?.click();

      // Reset form data
      setCreateFormData({
        name: "",
        academicYear: "",
        startDate: "",
        endDate: "",
        applicationNumberFormat: "APP/{YEAR}/{COUNTER}",
        applicationCounterStart: 1,
        admissionNumberFormat: "ADM/{YEAR}/{COUNTER}",
        admissionCounterStart: 1,
      });

      // Refresh cycles list
      fetchCycles();
    } catch (error) {
      console.error("Failed to create cycle:", error);
    }
  };

  useEffect(() => {
    fetchCycles();
  }, [filters]);

  useEffect(() => {
    fetchAcademicYears();
  }, []);

  useEffect(() => {
    if (!modalConfig.show) {
      setIsEditing(false);
      setEditFormData(null);
    }
  }, [modalConfig.show]);

  const fetchCycles = async () => {
    try {
      setIsLoading(true);
      const queryParams = new URLSearchParams();
      Object.entries(filters).forEach(([key, value]) => {
        if (value !== undefined && value !== "") {
          queryParams.append(key, value.toString());
        }
      });

      const response = await AxiosGetWithToken(
        `/admission-cycles?${queryParams.toString()}`
      );

      if (response) {
        const cyclesData = Array.isArray(response) ? response : response.cycles;
        setCycles(cyclesData);
        setPagination({
          total: cyclesData.length,
          page: 1,
          totalPages: 1,
          limit: 10,
        });
      }
    } catch (error) {
      console.error("Failed to fetch admission cycles:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getStatusBadgeClass = (status: string) => {
    switch (status) {
      case "ACTIVE":
        return "success";
      case "CLOSED":
        return "danger";
      case "DRAFT":
        return "warning";
      default:
        return "secondary";
    }
  };

  const handleSaveChanges = async (cycleId: string) => {
    if (!editFormData) return;

    try {
      const payload = {
        ...editFormData,
        _id: cycleId,
      };

      await AxiosPutWithToken(`/admission-cycles/${cycleId}`, payload, true);
      setIsEditing(false);
      setEditFormData(null);
      fetchCycles();

      // Close the modal
      const modalEl = document.getElementById("viewDetailsModal");
      const modalDismissButton = modalEl?.querySelector(
        '[data-bs-dismiss="modal"]'
      ) as HTMLElement;
      modalDismissButton?.click();
    } catch (error) {
      console.error("Failed to update cycle:", error);
    }
  };

  const handleShowQRCode = (url: string) => {
    setModalConfig({
      id: "qrModal",
      show: true,
      title: "Application Link",
      showQR: true,
      showLink: true,
      qrValue: url,
      linkValue: url,
    });

    const tempLink = document.createElement("a");
    tempLink.setAttribute("data-bs-toggle", "modal");
    tempLink.setAttribute("data-bs-target", "#qrModal");
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
  };

  const handleCloseCycleClick = (cycleId: string) => {
    setModalConfig({
      id: "closeCycleModal",
      show: true,
      title: "Close Admission Cycle",
      content: (
        <div className="text-start">
          <p className="mb-3">
            Are you sure you want to close this admission cycle? This action
            cannot be undone.
          </p>
          <div className="bg-light rounded border p-3">
            <div className="text-black fw-medium mb-2">Once closed:</div>
            <div>
              <div className="mb-1">No new applications can be submitted</div>
              <div className="mb-1">
                Existing applications will still be accessible
              </div>
              <div>The cycle status cannot be reverted back to active</div>
            </div>
          </div>
          <div className="d-flex justify-content-end mt-4">
            <button className="btn btn-light me-2" data-bs-dismiss="modal">
              Cancel
            </button>
            <button
              className="btn btn-danger"
              onClick={() => handleCloseCycle(cycleId)}
            >
              Close Cycle
            </button>
          </div>
        </div>
      ),
    });

    const tempLink = document.createElement("a");
    tempLink.setAttribute("data-bs-toggle", "modal");
    tempLink.setAttribute("data-bs-target", "#closeCycleModal");
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
  };

  const handleCloseCycle = async (cycleId: string) => {
    try {
      await AxiosPostWithToken(`/admission-cycles/${cycleId}/close`, {}, true);

      // Close the modal
      const modalEl = document.getElementById("closeCycleModal");
      const modalDismissButton = modalEl?.querySelector(
        '[data-bs-dismiss="modal"]'
      ) as HTMLElement;
      modalDismissButton?.click();

      fetchCycles();
    } catch (error) {
      console.error("Failed to close cycle:", error);
    }
  };

  const handleActivateCycleClick = (cycleId: string) => {
    setModalConfig({
      id: "activateCycleModal",
      show: true,
      title: "Activate Admission Cycle",
      content: (
        <div className="text-start">
          <p className="mb-3">
            Are you sure you want to activate this admission cycle?
          </p>
          <div className="bg-light rounded border p-3">
            <div className="text-black fw-medium mb-2">Once activated:</div>
            <div>
              <div className="mb-1">
                Fields except end date cannot be modified.
              </div>
              <div className="mb-1">Will start receiving applications.</div>
              <div>The cycle status cannot be reverted back to draft</div>
            </div>
          </div>
          <div className="d-flex justify-content-end mt-4">
            <button className="btn btn-light me-2" data-bs-dismiss="modal">
              Cancel
            </button>
            <button
              className="btn btn-success"
              onClick={() => handleActivateCycle(cycleId)}
            >
              Activate Cycle
            </button>
          </div>
        </div>
      ),
    });

    const tempLink = document.createElement("a");
    tempLink.setAttribute("data-bs-toggle", "modal");
    tempLink.setAttribute("data-bs-target", "#activateCycleModal");
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
  };

  const handleActivateCycle = async (cycleId: string) => {
    try {
      await AxiosPostWithToken(
        `/admission-cycles/${cycleId}/activate`,
        {},
        true
      );

      // Close the modal
      const modalEl = document.getElementById("activateCycleModal");
      const modalDismissButton = modalEl?.querySelector(
        '[data-bs-dismiss="modal"]'
      ) as HTMLElement;
      modalDismissButton?.click();

      fetchCycles();
    } catch (error) {
      console.error("Failed to close cycle:", error);
    }
  };

  const renderDateInput = (
    label: string,
    value: string,
    onChange: (date: dayjs.Dayjs | null) => void,
    disabled: boolean = false
  ) => (
    <div className="mb-3">
      <label className="form-label">{label}</label>
      <div className="input-icon position-relative">
        <DatePicker
          className="form-control datetimepicker"
          format={{
            format: "DD-MM-YYYY",
            type: "mask",
          }}
          value={value ? dayjs(value) : null}
          onChange={onChange}
          disabled={disabled}
          placeholder="Select Date"
        />
        <span className="input-icon-addon">
          <i className="ti ti-calendar" />
        </span>
      </div>
    </div>
  );

  const renderContent = (record: AdmissionCycle) => {
    if (isEditing) {
      return (
        <div className="text-start">
          {record.status === "ACTIVE" && (
            <div
              className="d-flex alert alert-info mb-4"
              style={{ alignContent: "center" }}
            >
              <i className="ti ti-info-circle me-2"></i>
              Only the end date can be modified when the admission cycle is
              active.
            </div>
          )}

          <div className="card border shadow-none mb-4">
            <div className="card-header bg-transparent">
              <h5 className="card-title mb-0">
                <i className="ti ti-file me-2"></i>
                Basic Details
              </h5>
            </div>
            <div className="card-body">
              <div className="row g-3">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="form-label">Name</label>
                    <input
                      type="text"
                      className="form-control"
                      value={editFormData?.name || ""}
                      onChange={(e) =>
                        setEditFormData((prev) => ({
                          ...prev!,
                          name: e.target.value,
                        }))
                      }
                      disabled={record.status === "ACTIVE"}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="form-label">Academic Year</label>
                    <select
                      className="form-select"
                      style={{ height: "38px" }}
                      value={editFormData?.academicYear}
                      onChange={(e) =>
                        setEditFormData((prev) => ({
                          ...prev!,
                          academicYear: e.target.value,
                        }))
                      }
                      disabled={record.status === "ACTIVE"}
                    >
                      <option value={record.academicYear._id}>
                        {record.academicYear.year}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card border shadow-none mb-4">
            <div className="card-header bg-transparent">
              <h5 className="card-title mb-0">
                <i className="ti ti-calendar me-2"></i>
                Duration
              </h5>
            </div>
            <div className="card-body">
              <div className="row g-3">
                <div className="col-md-6">
                  {renderDateInput(
                    "Start Date",
                    editFormData?.startDate || record.startDate,
                    (date) =>
                      setEditFormData((prev) => ({
                        ...prev!,
                        startDate: date ? date.toISOString() : prev!.startDate,
                      })),
                    record.status === "ACTIVE"
                  )}
                </div>
                <div className="col-md-6">
                  {renderDateInput(
                    "End Date",
                    editFormData?.endDate || record.endDate,
                    (date) =>
                      setEditFormData((prev) => ({
                        ...prev!,
                        endDate: date ? date.toISOString() : prev!.endDate,
                      }))
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="card border shadow-none">
            <div className="card-header bg-transparent">
              <h5 className="card-title mb-0">
                <i className="ti ti-settings me-2"></i>
                Configuration
              </h5>
            </div>
            <div className="card-body">
              <div className="row g-3">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="form-label">
                      Application Number Format
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={editFormData?.applicationNumberFormat || ""}
                      onChange={(e) =>
                        setEditFormData((prev) => ({
                          ...prev!,
                          applicationNumberFormat: e.target.value,
                        }))
                      }
                      disabled={record.status === "ACTIVE"}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="form-label">Counter Start</label>
                    <input
                      type="number"
                      className="form-control"
                      value={editFormData?.applicationCounterStart || 0}
                      onChange={(e) =>
                        setEditFormData((prev) => ({
                          ...prev!,
                          applicationCounterStart: parseInt(e.target.value),
                        }))
                      }
                      disabled={record.status === "ACTIVE"}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="form-label">
                      Admission Number Format
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={editFormData?.admissionNumberFormat || ""}
                      onChange={(e) =>
                        setEditFormData((prev) => ({
                          ...prev!,
                          admissionNumberFormat: e.target.value,
                        }))
                      }
                      disabled={record.status === "ACTIVE"}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="form-label">Counter Start</label>
                    <input
                      type="number"
                      className="form-control"
                      value={editFormData?.admissionCounterStart || 0}
                      onChange={(e) =>
                        setEditFormData((prev) => ({
                          ...prev!,
                          admissionCounterStart: parseInt(e.target.value),
                        }))
                      }
                      disabled={record.status === "ACTIVE"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    // View mode content
    return (
      <div className="text-start">
        <div className="card border shadow-none mb-4">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <div className="card-title">
                  {record.name || "Unnamed Cycle"}
                </div>
                <div className="text-muted">
                  Academic Year: {record.academicYear.year}
                </div>
              </div>
              <span
                className={`badge badge-soft-${getStatusBadgeClass(
                  record.status
                )}`}
              >
                {record.status}
              </span>
            </div>
          </div>
        </div>

        <div className="row g-3">
          <div className="col-md-4">
            <div className="card border shadow-none">
              <div className="card-body">
                <div className="text-muted small mb-1">Applications</div>
                <div className="h5 mb-0">{record.totalApplications}</div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card border shadow-none">
              <div className="card-body">
                <div className="text-muted small mb-1">Created At</div>
                <div className="h5 mb-0">
                  {moment(record.createdAt).format("DD MMM, YYYY")}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card border shadow-none">
              <div className="card-body">
                <div className="text-muted small mb-1">Last Updated</div>
                <div className="h5 mb-0">
                  {moment(record.updatedAt).format("DD MMM, YYYY")}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card border shadow-none mb-4">
          <div className="card-header bg-transparent">
            <h5 className="card-title mb-0">
              <i className="ti ti-calendar me-2"></i>
              Duration
            </h5>
          </div>
          <div className="card-body">
            <div className="row g-3">
              <div className="col-sm-6">
                <div className="text-muted small mb-1">Start Date</div>
                <div className="fw-medium">
                  {moment(record.startDate).format("DD MMM, YYYY")}
                </div>
              </div>
              <div className="col-sm-6">
                <div className="text-muted small mb-1">End Date</div>
                <div className="fw-medium">
                  {moment(record.endDate).format("DD MMM, YYYY")}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card border shadow-none mb-4">
          <div className="card-header bg-transparent">
            <h5 className="card-title mb-0">
              <i className="ti ti-settings me-2"></i>
              Configuration
            </h5>
          </div>
          <div className="card-body">
            <div className="row g-3">
              <div className="col-sm-6">
                <div className="text-muted small mb-1">
                  Application Number Format
                </div>
                <div className="fw-medium">
                  {record.applicationNumberFormat}
                </div>
              </div>
              <div className="col-sm-6">
                <div className="text-muted small mb-1">Counter Start</div>
                <div className="fw-medium">
                  {record.applicationCounterStart}
                </div>
              </div>
              <div className="col-sm-6">
                <div className="text-muted small mb-1">
                  Admission Number Format
                </div>
                <div className="fw-medium">{record.admissionNumberFormat}</div>
              </div>
              <div className="col-sm-6">
                <div className="text-muted small mb-1">Counter Start</div>
                <div className="fw-medium">{record.admissionCounterStart}</div>
              </div>
            </div>
          </div>
        </div>

        <div className="card border shadow-none">
          <div className="card-header bg-transparent">
            <h5 className="card-title mb-0">
              <i className="ti ti-user me-2"></i>
              Created & Modified By
            </h5>
          </div>
          <div className="card-body">
            <div className="row g-3">
              <div className="col-sm-6">
                <div className="text-muted small mb-1">Created By</div>
                <div className="fw-medium">{record.createdBy.username}</div>
              </div>
              <div className="col-sm-6">
                <div className="text-muted small mb-1">Last Updated By</div>
                <div className="fw-medium">{record.updatedBy.username}</div>
              </div>
            </div>
          </div>
        </div>

        {record.status === "ACTIVE" && record.publicUrl && (
          <div className="alert alert-info mt-4 mb-0 d-flex align-items-center">
            <div>
              <div className="fw-medium mb-1">Public Application URL</div>
              <div className="small">{record.publicUrl}</div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderActions = (record: AdmissionCycle) => {
    return (
      <div className="modal-footer p-0 pt-2 mt-4 border-none">
        {!isEditing && record.status !== "CLOSED" && (
          <button
            className="btn btn-primary"
            onClick={() => {
              setIsEditing(true);
              setEditFormData({
                name: record.name || "",
                academicYear: record.academicYear._id,
                startDate: record.startDate,
                endDate: record.endDate,
                applicationNumberFormat: record.applicationNumberFormat,
                applicationCounterStart: record.applicationCounterStart,
                admissionNumberFormat: record.admissionNumberFormat,
                admissionCounterStart: record.admissionCounterStart,
              });
            }}
          >
            <i className="ti ti-edit me-2"></i>
            Edit
          </button>
        )}

        {isEditing && (
          <>
            <button
              className="btn btn-light me-2"
              onClick={() => {
                setIsEditing(false);
                setEditFormData(null);
              }}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary"
              onClick={() => handleSaveChanges(record._id)}
            >
              Save Changes
            </button>
          </>
        )}
      </div>
    );
  };

  const handleViewDetails = (record: AdmissionCycle) => {
    setSelectedRecord(record);
    setModalConfig({
      id: "viewDetailsModal",
      modalSize: "modal-lg",
      show: true,
      title: "Admission Cycle Details",
    });

    const tempLink = document.createElement("a");
    tempLink.setAttribute("data-bs-toggle", "modal");
    tempLink.setAttribute("data-bs-target", "#viewDetailsModal");
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
  };

  const columns = [
    {
      title: "Name/Academic Year",
      render: (_: any, record: AdmissionCycle) => (
        <div>
          <div className="fw-bold">{record.name || "Unnamed Cycle"}</div>
          <div className="text-muted small">{record.academicYear.year}</div>
        </div>
      ),
      sorter: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status: string) => (
        <span className={`badge badge-soft-${getStatusBadgeClass(status)}`}>
          {status}
        </span>
      ),
      sorter: true,
    },
    {
      title: "Duration",
      render: (_: any, record: AdmissionCycle) => (
        <div>
          <div>From: {moment(record.startDate).format("DD MMM, YYYY")}</div>
          <div>To: {moment(record.endDate).format("DD MMM, YYYY")}</div>
        </div>
      ),
      sorter: true,
    },
    {
      title: "Applications",
      dataIndex: "totalApplications",
      sorter: true,
    },
    {
      title: "Created By",
      render: (_: any, record: AdmissionCycle) => (
        <div>{record.createdBy.username}</div>
      ),
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      render: (date: string) => moment(date).format("DD MMM, YYYY"),
      sorter: true,
    },
    {
      title: "Actions",
      width: 200,
      render: (_: any, record: AdmissionCycle) => (
        <div className="d-flex align-items-center">
          <button
            className="btn btn-outline-light bg-white btn-icon d-flex align-items-center justify-content-center rounded-circle p-0 me-2"
            onClick={() => {
              handleViewDetails(record);
            }}
            title="View Details"
          >
            <i className="ti ti-eye" />
          </button>

          {record.status === "ACTIVE" && (
            <>
              <button
                className="btn btn-outline-light bg-white btn-icon d-flex align-items-center justify-content-center rounded-circle p-0 me-2"
                onClick={() => handleShowQRCode(record.publicUrl!)}
                title="Show QR & Link"
              >
                <i className="ti ti-qrcode" />
              </button>

              <button
                className="btn btn-outline-light bg-white btn-icon d-flex align-items-center justify-content-center rounded-circle p-0 me-2"
                onClick={() => handleCloseCycleClick(record._id)}
                title="Close Cycle"
              >
                <i className="ti ti-lock text-danger" />
              </button>
            </>
          )}

          {record.status === "DRAFT" && (
            <>
              <button
                className="btn btn-outline-light bg-white btn-icon d-flex align-items-center justify-content-center rounded-circle p-0 me-2"
                onClick={() => handleActivateCycleClick(record._id)}
                title="Activate Cycle"
              >
                <i className="ti ti-lock text-success" />
              </button>
            </>
          )}
        </div>
      ),
    },
  ];

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    setFilters((prev) => ({
      ...prev,
      page: pagination.current,
      sortBy: sorter.field,
      order: sorter.order === "ascend" ? "asc" : "desc",
    }));
  };

  const renderCreateForm = () => {
    const applicationFormatOptions = [
      {
        label: "APP/{YEAR}/{COUNTER}",
        example: "APP/2024/00001",
        description: "Basic format with year",
      },
      {
        label: "APP/{YEAR}/{MONTH}/{COUNTER}",
        example: "APP/2024/03/00001",
        description: "Format with year and month",
      },
      {
        label: "APP/{YEAR}-{MONTH}-{DAY}/{COUNTER}",
        example: "APP/2024-03-15/00001",
        description: "Format with complete date",
      },
      {
        label: "{YEAR}/APP/{COUNTER}",
        example: "2024/APP/00001",
        description: "Year prefixed format",
      },
      {
        label: "APP-{YEAR}-{COUNTER}",
        example: "APP-2024-00001",
        description: "Hyphenated format",
      },
      {
        label: "APP/{MONTH}/{YEAR}/{COUNTER}",
        example: "APP/03/2024/00001",
        description: "Month first format",
      },
    ];

    const admissionFormatOptions = [
      {
        label: "ADM/{YEAR}/{COUNTER}",
        example: "ADM/2024/00001",
        description: "Basic format with year",
      },
      {
        label: "ADM/{YEAR}/{MONTH}/{COUNTER}",
        example: "ADM/2024/03/00001",
        description: "Format with year and month",
      },
      {
        label: "ADM/{YEAR}-{MONTH}-{DAY}/{COUNTER}",
        example: "ADM/2024-03-15/00001",
        description: "Format with complete date",
      },
      {
        label: "{YEAR}/ADM/{COUNTER}",
        example: "2024/ADM/00001",
        description: "Year prefixed format",
      },
      {
        label: "ADM-{YEAR}-{COUNTER}",
        example: "ADM-2024-00001",
        description: "Hyphenated format",
      },
      {
        label: "ADM/{MONTH}/{YEAR}/{COUNTER}",
        example: "ADM/03/2024/00001",
        description: "Month first format",
      },
    ];

    return (
      <div className="text-start">
        <div className="card border shadow-none mb-4">
          <div className="card-header bg-transparent">
            <h5 className="card-title mb-0">
              <i className="ti ti-file me-2"></i>
              Basic Details
            </h5>
          </div>
          <div className="card-body">
            <div className="row g-3">
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter cycle name"
                    value={createFormData.name}
                    onChange={(e) =>
                      setCreateFormData((prev) => ({
                        ...prev,
                        name: e.target.value,
                      }))
                    }
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label">Academic Year</label>
                  <CommonSelect
                    className="select"
                    options={academicYears.map((year) => ({
                      label: year.year,
                      value: year._id,
                    }))}
                    value={createFormData.academicYear}
                    onChange={(value: Option | null) =>
                      setCreateFormData((prev) => ({
                        ...prev,
                        academicYear: value ? value.value : "", // Extract the value property from Option
                      }))
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card border shadow-none mb-4">
          <div className="card-header bg-transparent">
            <h5 className="card-title mb-0">
              <i className="ti ti-calendar me-2"></i>
              Duration
            </h5>
          </div>
          <div className="card-body">
            <div className="row g-3">
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label">Start Date</label>
                  <div className="input-icon position-relative">
                    <DatePicker
                      className="form-control datetimepicker"
                      format={{
                        format: "DD-MM-YYYY",
                        type: "mask",
                      }}
                      value={
                        createFormData.startDate
                          ? dayjs(createFormData.startDate)
                          : null
                      }
                      onChange={(date) =>
                        setCreateFormData((prev) => ({
                          ...prev,
                          startDate: date ? date.toISOString() : "",
                        }))
                      }
                      placeholder="Select start date"
                    />
                    <span className="input-icon-addon">
                      <i className="ti ti-calendar" />
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label">End Date</label>
                  <div className="input-icon position-relative">
                    <DatePicker
                      className="form-control datetimepicker"
                      format={{
                        format: "DD-MM-YYYY",
                        type: "mask",
                      }}
                      value={
                        createFormData.endDate
                          ? dayjs(createFormData.endDate)
                          : null
                      }
                      onChange={(date) =>
                        setCreateFormData((prev) => ({
                          ...prev,
                          endDate: date ? date.toISOString() : "",
                        }))
                      }
                      placeholder="Select end date"
                    />
                    <span className="input-icon-addon">
                      <i className="ti ti-calendar" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card border shadow-none">
          <div className="card-header bg-transparent">
            <h5 className="card-title mb-0">
              <i className="ti ti-settings me-2"></i>
              Number Format Configuration
            </h5>
          </div>
          <div className="card-body">
            <div className="row g-3">
              <div className="col-12">
                <div className="mb-4">
                  <label className="form-label">
                    Application Number Format
                  </label>
                  <select
                    className="form-select mb-2"
                    value={createFormData.applicationNumberFormat}
                    onChange={(e) =>
                      setCreateFormData((prev) => ({
                        ...prev,
                        applicationNumberFormat: e.target.value,
                      }))
                    }
                  >
                    {applicationFormatOptions.map((option, index) => (
                      <option key={index} value={option.label}>
                        {option.label} ({option.description}) - Example:{" "}
                        {option.example}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mb-3">
                  <label className="form-label">
                    Application Counter Start
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter starting counter number"
                    value={createFormData.applicationCounterStart}
                    onChange={(e) =>
                      setCreateFormData((prev) => ({
                        ...prev,
                        applicationCounterStart: parseInt(e.target.value),
                      }))
                    }
                  />
                </div>
              </div>

              <div className="col-12">
                <div className="mb-4">
                  <label className="form-label">Admission Number Format</label>
                  <select
                    className="form-select mb-2"
                    value={createFormData.admissionNumberFormat}
                    onChange={(e) =>
                      setCreateFormData((prev) => ({
                        ...prev,
                        admissionNumberFormat: e.target.value,
                      }))
                    }
                  >
                    {admissionFormatOptions.map((option, index) => (
                      <option key={index} value={option.label}>
                        {option.label} ({option.description}) - Example:{" "}
                        {option.example}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mb-3">
                  <label className="form-label">Admission Counter Start</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter starting counter number"
                    value={createFormData.admissionCounterStart}
                    onChange={(e) =>
                      setCreateFormData((prev) => ({
                        ...prev,
                        admissionCounterStart: parseInt(e.target.value),
                      }))
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-end mt-4">
          <button className="btn btn-light me-2" data-bs-dismiss="modal">
            Cancel
          </button>
          <button className="btn btn-primary" onClick={handleCreateSubmit}>
            Create Cycle
          </button>
        </div>
      </div>
    );
  };

  const handleCreateCycle = () => {
    setModalConfig({
      id: "createCycleModal",
      modalSize: "modal-lg",
      show: true,
      title: "Create New Admission Cycle",
    });

    const tempLink = document.createElement("a");
    tempLink.setAttribute("data-bs-toggle", "modal");
    tempLink.setAttribute("data-bs-target", "#createCycleModal");
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
  };

  useEffect(() => {
    const modal = document.getElementById("viewDetailsModal");

    const handleModalClose = () => {
      setIsEditing(false);
    };

    if (modal) {
      modal.addEventListener("hidden.bs.modal", handleModalClose);

      return () => {
        modal.removeEventListener("hidden.bs.modal", handleModalClose);
      };
    }
  }, []);

  return (
    <div className="page-wrapper">
      <CustomModal
        id="qrModal"
        onClose={() => setModalConfig((prev) => ({ ...prev, show: false }))}
        title={modalConfig.title}
        showQR={modalConfig.showQR}
        showLink={modalConfig.showLink}
        qrValue={modalConfig.qrValue}
        linkValue={modalConfig.linkValue}
      >
        {modalConfig.content}
      </CustomModal>

      <CustomModal
        id="closeCycleModal"
        onClose={() => setModalConfig((prev) => ({ ...prev, show: false }))}
        title={modalConfig.title}
        showQR={modalConfig.showQR}
        showLink={modalConfig.showLink}
        qrValue={modalConfig.qrValue}
        linkValue={modalConfig.linkValue}
      >
        <CloseModalContent
          cycleId={selectedRecord._id}
          onClose={() => {
            const modalEl = document.getElementById("closeCycleModal");
            const modalDismissButton = modalEl?.querySelector(
              '[data-bs-dismiss="modal"]'
            ) as HTMLElement;
            modalDismissButton?.click();
          }}
          onCloseCycle={handleCloseCycle}
        />
      </CustomModal>

      <CustomModal
        id="activateCycleModal"
        onClose={() => setModalConfig((prev) => ({ ...prev, show: false }))}
        title={modalConfig.title}
        showQR={modalConfig.showQR}
        showLink={modalConfig.showLink}
        qrValue={modalConfig.qrValue}
        linkValue={modalConfig.linkValue}
      >
        <ActivateModalContent
          cycleId={selectedRecord._id}
          onClose={() => {
            const modalEl = document.getElementById("activateCycleModal");
            const modalDismissButton = modalEl?.querySelector(
              '[data-bs-dismiss="modal"]'
            ) as HTMLElement;
            modalDismissButton?.click();
          }}
          onActivate={handleActivateCycle}
        />
      </CustomModal>

      <CustomModal
        id="viewDetailsModal"
        modalSize="modal-lg"
        onClose={() => setModalConfig((prev) => ({ ...prev, show: false }))}
        title={modalConfig.title}
      >
        {renderContent(selectedRecord)}
        {renderActions(selectedRecord)}
      </CustomModal>

      <CustomModal
        id="createCycleModal"
        modalSize="modal-lg"
        onClose={() => setModalConfig((prev) => ({ ...prev, show: false }))}
        title={modalConfig.title}
      >
        {renderCreateForm()}
      </CustomModal>

      <div className="content">
        {/* Page Header */}
        <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
          <div className="my-auto mb-2">
            <h3 className="page-title mb-1">Admission Cycles</h3>
            <nav>
              <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to={routes.adminDashboard}>Dashboard</Link>
                </li>
                <li className="breadcrumb-item active">Admission Cycles</li>
              </ol>
            </nav>
          </div>
          <div className="d-flex my-xl-auto right-content align-items-center">
            <TooltipOption
              showAddButton={true}
              addButtonConfig={{
                show: true,
                text: "New Cycle",
                icon: "ti ti-plus",
                onClick: handleCreateCycle,
              }}
              onRefresh={fetchCycles}
              showExport={false}
            />
          </div>
        </div>

        {/* Cycles List Card */}
        <div className="card position-relative">
          {isLoading && (
            <div
              className="position-absolute w-100 h-100 d-flex align-items-center justify-content-center bg-white"
              style={{ zIndex: 1, opacity: 0.8 }}
            >
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
          <div className="card-body p-0 py-3">
            <Table
              dataSource={cycles}
              columns={columns}
              Selection={false}
              pagination={{
                total: pagination.total,
                current: pagination.page,
                pageSize: pagination.limit,
                locale: { items_per_page: "" },
                nextIcon: <span>Next</span>,
                prevIcon: <span>Prev</span>,
              }}
              onChange={handleTableChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdmissionCycles;
