import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { TagsInput } from "react-tag-input-component";
import { useApi } from "../../../utils/api";
import ImageWithBasePath from "../../../core/common/imageWithBasePath";
import { useFormValidation } from "./validation/useFormValidation";

interface Sibling {
  name: string;
  class: string;
  section: string;
  relation: string;
}

const ApplyAdmission = () => {
  const { cycleId } = useParams();
  const { get, post } = useApi();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [admissionCycle, setAdmissionCycle] = useState<any>(null);
  const [submitting, setSubmitting] = useState(false);
  const { errors, validateForm, clearError } = useFormValidation();

  // Personal Information
  const [nameEnglish, setNameEnglish] = useState("");
  const [nameTamil, setNameTamil] = useState("");
  const [selectedGender, setSelectedGender] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState<null | string>(null);
  const [bloodGroup, setBloodGroup] = useState("");
  const [religion, setReligion] = useState("");
  const [community, setCommunity] = useState("");
  const [caste, setCaste] = useState("");
  const [motherTongue, setMotherTongue] = useState("");
  const [nationality, setNationality] = useState("Indian");
  const [state, setState] = useState("");
  const [classAppliedFor, setClassAppliedFor] = useState("");
  const [identificationMarks, setIdentificationMarks] = useState("");
  const [allergies, setAllergies] = useState<string[]>([]);
  const [medications, setMedications] = useState<string[]>([]);
  const [address, setAddress] = useState("");

  // Parent info
  const [fatherName, setFatherName] = useState("");
  const [fatherPhoneNumber, setFatherPhoneNumber] = useState("");
  const [fatherOccupation, setFatherOccupation] = useState("");
  const [motherName, setMotherName] = useState("");
  const [motherPhoneNumber, setMotherPhoneNumber] = useState("");
  const [motherOccupation, setMotherOccupation] = useState("");

  // Guardian info
  const [hasGuardian, setHasGuardian] = useState(false);
  const [guardianName, setGuardianName] = useState("");
  const [guardianRelation, setGuardianRelation] = useState("");
  const [guardianPhoneNumber, setGuardianPhoneNumber] = useState("");
  const [guardianOccupation, setGuardianOccupation] = useState("");

  // Siblings info
  const [hasSiblings, setHasSiblings] = useState(false);
  const [siblingsData, setSiblingsData] = useState<Sibling[]>([
    { name: "", class: "", section: "", relation: "" },
  ]);

  // Previous school
  const [previousSchoolName, setPreviousSchoolName] = useState("");
  const [previousSchoolPeriodOfStudy, setPreviousSchoolPeriodOfStudy] =
    useState("");
  const [previousSchoolClass, setPreviousSchoolClass] = useState("");

  // Success modal state
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [applicationNo, setApplicationNo] = useState("");

  useEffect(() => {
    const fetchAdmissionCycle = async () => {
      try {
        const response = await get(`/public/applications/${cycleId}`);
        setAdmissionCycle(response);
        setLoading(false);
      } catch (error: any) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchAdmissionCycle();
  }, [cycleId]);

  const addNewSibling = () => {
    setSiblingsData([
      ...siblingsData,
      { name: "", class: "", section: "", relation: "" },
    ]);
  };

  const handleSiblingInputChange = (
    index: number,
    field: keyof Sibling,
    value: string
  ) => {
    const newSiblings = [...siblingsData];
    newSiblings[index][field] = value;
    setSiblingsData(newSiblings);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const formData = {
      nameEnglish,
      selectedGender,
      dateOfBirth,
      bloodGroup,
      religion,
      community,
      caste,
      motherTongue,
      classAppliedFor,
      address,
      fatherName,
      fatherPhoneNumber,
      fatherOccupation,
      motherName,
      motherPhoneNumber,
      motherOccupation,
      hasGuardian,
      guardianName,
      guardianPhoneNumber,
      guardianOccupation,
      guardianRelation,
      hasSiblings,
      siblingsData,
      identificationMarks,
      previousSchoolName,
      previousSchoolPeriodOfStudy,
      previousSchoolClass,
    };

    const validationResult = validateForm(formData);

    if (!validationResult.isValid) {
      // Map field names to input IDs
      const fieldToId: { [key: string]: string } = {
        // Personal Information
        nameEnglish: "name-english-input",
        selectedGender: "gender-select",
        dateOfBirth: "dob-input",
        bloodGroup: "blood-group-input",
        religion: "religion-input",
        community: "community-input",
        caste: "caste-input",
        motherTongue: "mother-tongue-input",
        classAppliedFor: "class-applied-input",

        // Parent Information
        fatherName: "father-name-input",
        fatherPhoneNumber: "father-phone-input",
        fatherOccupation: "father-occupation-input",
        motherName: "mother-name-input",
        motherPhoneNumber: "mother-phone-input",
        motherOccupation: "mother-occupation-input",

        // Guardian Information (when hasGuardian is true)
        guardianName: "guardian-name-input",
        guardianRelation: "guardian-relation-input",
        guardianPhoneNumber: "guardian-phone-input",
        guardianOccupation: "guardian-occupation-input",

        // Address
        address: "address-input",

        // Medical Information
        identificationMarks: "identification-marks-input",

        // Previous School
        previousSchoolName: "previous-school-name-input",
        previousSchoolPeriodOfStudy: "previous-school-period-input",
        previousSchoolClass: "previous-school-class-input",
      };

      if (validationResult.firstErrorField) {
        const fieldId = fieldToId[validationResult.firstErrorField];
        if (fieldId) {
          const element = document.getElementById(fieldId);
          if (element) {
            // Scroll to element with offset
            const headerOffset = 100; // Adjust this value based on your fixed header height
            const elementPosition = element.getBoundingClientRect().top;
            const offsetPosition =
              elementPosition + window.pageYOffset - headerOffset;

            window.scrollTo({
              top: offsetPosition,
              behavior: "smooth",
            });

            // Focus the element
            element.focus();
          }
        }
      }
      return;
    }

    setSubmitting(true);

    try {
      const payload = {
        name: {
          english: nameEnglish,
          tamil: nameTamil || undefined,
        },
        gender: selectedGender,
        dateOfBirth,
        bloodGroup,
        religion,
        community,
        caste,
        motherTongue,
        nationality,
        state,
        classAppliedFor,
        identificationMarks,
        allergies,
        medications,
        address,
        father: {
          name: fatherName,
          mobileNumber: fatherPhoneNumber,
          occupation: fatherOccupation,
        },
        mother: {
          name: motherName,
          mobileNumber: motherPhoneNumber,
          occupation: motherOccupation,
        },
        hasGuardian,
        ...(hasGuardian && {
          guardian: {
            name: guardianName,
            mobileNumber: guardianPhoneNumber,
            occupation: guardianOccupation,
            relation: guardianRelation,
          },
        }),
        hasSiblings,
        ...(hasSiblings && {
          siblings: siblingsData,
        }),
        previousSchool: {
          name: previousSchoolName,
          periodOfStudy: previousSchoolPeriodOfStudy,
          class: previousSchoolClass,
        },
      };

      const response = await post("/public/applications", payload);
      setApplicationNo(response.data.applicationNo);
      setShowSuccessModal(true);
    } catch (error: any) {
      alert(`Failed to submit application: ${error.message}`);
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) {
    return (
      <div className="min-vh-100 d-flex align-items-center justify-content-center">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  if (error || !admissionCycle) {
    return (
      <div className="min-vh-100 d-flex align-items-center justify-content-center">
        <div className="text-center">
          <h2>Admission Cycle Not Found</h2>
          <p className="text-muted">
            The admission cycle you're looking for doesn't exist or has ended.
          </p>
        </div>
      </div>
    );
  }

  if (admissionCycle.status !== "ACTIVE") {
    return (
      <div className="min-vh-100 d-flex align-items-center justify-content-center">
        <div className="text-center">
          <h2>Admissions Closed</h2>
          <p className="text-muted">
            The admission cycle is currently not active.
          </p>
          <p>
            Start Date: {dayjs(admissionCycle.startDate).format("DD/MM/YYYY")}
          </p>
          <p>End Date: {dayjs(admissionCycle.endDate).format("DD/MM/YYYY")}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="container-fluid py-5">
      <div className="container">
        <div className="text-center mb-4">
          <ImageWithBasePath
            src="assets/img/icons/lions-img.jpg"
            alt="School Logo"
            className="img-fluid mb-3"
            width={100}
          />
          <h2>{admissionCycle.name || "Admission Application"}</h2>
          <p className="text-muted">
            Academic Year: {admissionCycle.academicYear.year}
            <br />
            Applications Open:{" "}
            {dayjs(admissionCycle.startDate).format("DD/MM/YYYY")} -{" "}
            {dayjs(admissionCycle.endDate).format("DD/MM/YYYY")}
          </p>
        </div>

        <form onSubmit={handleSubmit} className="needs-validation" noValidate>
          {/* Personal Information */}
          <div className="card mb-4">
            <div className="card-header bg-light">
              <h4 className="mb-0">Personal Information</h4>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-4 mb-3">
                  <label className="form-label">Name in English*</label>
                  <input
                    type="text"
                    id="name-english-input"
                    className={`form-control ${
                      errors.nameEnglish ? "is-invalid" : ""
                    }`}
                    required
                    value={nameEnglish}
                    onChange={(e) => {
                      setNameEnglish(e.target.value);
                      clearError("nameEnglish");
                    }}
                  />
                  {errors.nameEnglish && (
                    <div className="invalid-feedback">
                      This field is required
                    </div>
                  )}
                </div>
                <div className="col-md-4 mb-3">
                  <label className="form-label">Name in Tamil</label>
                  <input
                    type="text"
                    className="form-control"
                    value={nameTamil}
                    onChange={(e) => setNameTamil(e.target.value)}
                  />
                </div>
                <div className="col-md-4 mb-3">
                  <label className="form-label">Class Applied For*</label>
                  <input
                    type="text"
                    id="class-applied-input"
                    className={`form-control ${
                      errors.classAppliedFor ? "is-invalid" : ""
                    }`}
                    required
                    value={classAppliedFor}
                    onChange={(e) => {
                      setClassAppliedFor(e.target.value);
                      clearError("classAppliedFor");
                    }}
                  />
                  {errors.classAppliedFor && (
                    <div className="invalid-feedback">
                      {errors.classAppliedFor}
                    </div>
                  )}
                </div>
                <div className="col-md-4 mb-3">
                  <label className="form-label">Gender*</label>
                  <select
                    id="gender-select"
                    className={`form-select ${
                      errors.selectedGender ? "is-invalid" : ""
                    }`}
                    required
                    value={selectedGender}
                    onChange={(e) => {
                      setSelectedGender(e.target.value);
                      clearError("selectedGender");
                    }}
                  >
                    <option value="">Select Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                  {errors.selectedGender && (
                    <div className="invalid-feedback">
                      {errors.selectedGender}
                    </div>
                  )}
                </div>
                <div className="col-md-4 mb-3">
                  <label className="form-label">Date of Birth*</label>
                  <div className="input-group">
                    <DatePicker
                      id="dob-input"
                      className={`form-control ${
                        errors.dateOfBirth ? "is-invalid" : ""
                      }`}
                      format="DD-MM-YYYY"
                      value={dateOfBirth ? dayjs(dateOfBirth) : null}
                      onChange={(date) => {
                        if (date) {
                          setDateOfBirth(date.toISOString());
                          clearError("dateOfBirth");
                        }
                      }}
                      placeholder="Select Date"
                    />
                    {errors.dateOfBirth && (
                      <div className="invalid-feedback">
                        {errors.dateOfBirth}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-4 mb-3">
                  <label className="form-label">Blood Group*</label>
                  <input
                    type="text"
                    id="blood-group-input"
                    className={`form-control ${
                      errors.bloodGroup ? "is-invalid" : ""
                    }`}
                    required
                    value={bloodGroup}
                    onChange={(e) => {
                      setBloodGroup(e.target.value);
                      clearError("bloodGroup");
                    }}
                  />
                  {errors.bloodGroup && (
                    <div className="invalid-feedback">{errors.bloodGroup}</div>
                  )}
                </div>
                <div className="col-md-4 mb-3">
                  <label className="form-label">Religion*</label>
                  <input
                    type="text"
                    id="religion-input"
                    className={`form-control ${
                      errors.religion ? "is-invalid" : ""
                    }`}
                    required
                    value={religion}
                    onChange={(e) => {
                      setReligion(e.target.value);
                      clearError("religion");
                    }}
                  />
                  {errors.religion && (
                    <div className="invalid-feedback">{errors.religion}</div>
                  )}
                </div>
                <div className="col-md-4 mb-3">
                  <label className="form-label">Community*</label>
                  <input
                    type="text"
                    id="community-input"
                    className={`form-control ${
                      errors.community ? "is-invalid" : ""
                    }`}
                    required
                    value={community}
                    onChange={(e) => {
                      setCommunity(e.target.value);
                      clearError("community");
                    }}
                  />
                  {errors.community && (
                    <div className="invalid-feedback">{errors.community}</div>
                  )}
                </div>
                <div className="col-md-4 mb-3">
                  <label className="form-label">Caste*</label>
                  <input
                    type="text"
                    id="caste-input"
                    className={`form-control ${
                      errors.caste ? "is-invalid" : ""
                    }`}
                    required
                    value={caste}
                    onChange={(e) => {
                      setCaste(e.target.value);
                      clearError("caste");
                    }}
                  />
                  {errors.caste && (
                    <div className="invalid-feedback">{errors.caste}</div>
                  )}
                </div>
                <div className="col-md-4 mb-3">
                  <label className="form-label">Mother Tongue*</label>
                  <input
                    type="text"
                    id="mother-tongue-input"
                    className={`form-control ${
                      errors.motherTongue ? "is-invalid" : ""
                    }`}
                    required
                    value={motherTongue}
                    onChange={(e) => {
                      setMotherTongue(e.target.value);
                      clearError("motherTongue");
                    }}
                  />
                  {errors.motherTongue && (
                    <div className="invalid-feedback">
                      {errors.motherTongue}
                    </div>
                  )}
                </div>
                <div className="col-md-4 mb-3">
                  <label className="form-label">Nationality</label>
                  <input
                    type="text"
                    className="form-control"
                    value={nationality}
                    onChange={(e) => setNationality(e.target.value)}
                  />
                </div>
                <div className="col-md-4 mb-3">
                  <label className="form-label">State</label>
                  <input
                    type="text"
                    className="form-control"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Parents Information */}
          <div className="card mb-4">
            <div className="card-header bg-light">
              <h4 className="mb-0">Parents Information</h4>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-12 mb-3">
                  <h5>Father's Information</h5>
                </div>
                <div className="col-md-4 mb-3">
                  <label className="form-label">Father's Name*</label>
                  <input
                    type="text"
                    id="father-name-input"
                    className={`form-control ${
                      errors.fatherName ? "is-invalid" : ""
                    }`}
                    required
                    value={fatherName}
                    onChange={(e) => {
                      setFatherName(e.target.value);
                      clearError("fatherName");
                    }}
                  />
                  {errors.fatherName && (
                    <div className="invalid-feedback">{errors.fatherName}</div>
                  )}
                </div>
                <div className="col-md-4 mb-3">
                  <label className="form-label">Mobile Number*</label>
                  <input
                    type="tel"
                    id="father-phone-input"
                    className={`form-control ${
                      errors.fatherPhoneNumber ? "is-invalid" : ""
                    }`}
                    required
                    value={fatherPhoneNumber}
                    onChange={(e) => {
                      setFatherPhoneNumber(e.target.value);
                      clearError("fatherPhoneNumber");
                    }}
                  />
                  {errors.fatherPhoneNumber && (
                    <div className="invalid-feedback">
                      {errors.fatherPhoneNumber}
                    </div>
                  )}
                </div>
                <div className="col-md-4 mb-3">
                  <label className="form-label">Occupation*</label>
                  <input
                    type="text"
                    id="father-occupation-input"
                    className={`form-control ${
                      errors.fatherOccupation ? "is-invalid" : ""
                    }`}
                    required
                    value={fatherOccupation}
                    onChange={(e) => {
                      setFatherOccupation(e.target.value);
                      clearError("fatherOccupation");
                    }}
                  />
                  {errors.fatherOccupation && (
                    <div className="invalid-feedback">
                      {errors.fatherOccupation}
                    </div>
                  )}
                </div>

                <div className="col-md-12 mb-3">
                  <h5>Mother's Information</h5>
                </div>
                <div className="col-md-4 mb-3">
                  <label className="form-label">Mother's Name*</label>
                  <input
                    type="text"
                    id="mother-name-input"
                    className={`form-control ${
                      errors.motherName ? "is-invalid" : ""
                    }`}
                    required
                    value={motherName}
                    onChange={(e) => {
                      setMotherName(e.target.value);
                      clearError("motherName");
                    }}
                  />
                  {errors.motherName && (
                    <div className="invalid-feedback">{errors.motherName}</div>
                  )}
                </div>
                <div className="col-md-4 mb-3">
                  <label className="form-label">Mobile Number*</label>
                  <input
                    type="tel"
                    id="mother-phone-input"
                    className={`form-control ${
                      errors.motherPhoneNumber ? "is-invalid" : ""
                    }`}
                    required
                    value={motherPhoneNumber}
                    onChange={(e) => {
                      setMotherPhoneNumber(e.target.value);
                      clearError("motherPhoneNumber");
                    }}
                  />
                  {errors.motherPhoneNumber && (
                    <div className="invalid-feedback">
                      {errors.motherPhoneNumber}
                    </div>
                  )}
                </div>
                <div className="col-md-4 mb-3">
                  <label className="form-label">Occupation*</label>
                  <input
                    type="text"
                    id="mother-occupation-input"
                    className={`form-control ${
                      errors.motherOccupation ? "is-invalid" : ""
                    }`}
                    required
                    value={motherOccupation}
                    onChange={(e) => {
                      setMotherOccupation(e.target.value);
                      clearError("motherOccupation");
                    }}
                  />
                  {errors.motherOccupation && (
                    <div className="invalid-feedback">
                      {errors.motherOccupation}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Guardian Information */}
          <div className="card mb-4">
            <div className="card-header bg-light">
              <h4 className="mb-0">Guardian Information</h4>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-12 mb-3">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="hasGuardian"
                      checked={hasGuardian}
                      onChange={(e) => setHasGuardian(e.target.checked)}
                    />
                    <label className="form-check-label" htmlFor="hasGuardian">
                      Has Guardian (other than parents)
                    </label>
                  </div>
                </div>

                {hasGuardian && (
                  <>
                    <div className="col-md-3 mb-3">
                      <label className="form-label">Guardian Name*</label>
                      <input
                        type="text"
                        id="guardian-name-input"
                        className={`form-control ${
                          errors.guardianName ? "is-invalid" : ""
                        }`}
                        required={hasGuardian}
                        value={guardianName}
                        onChange={(e) => {
                          setGuardianName(e.target.value);
                          clearError("guardianName");
                        }}
                      />
                      {errors.guardianName && (
                        <div className="invalid-feedback">
                          {errors.guardianName}
                        </div>
                      )}
                    </div>
                    <div className="col-md-3 mb-3">
                      <label className="form-label">Relationship*</label>
                      <input
                        type="text"
                        id="guardian-relation-input"
                        className={`form-control ${
                          errors.guardianRelation ? "is-invalid" : ""
                        }`}
                        required={hasGuardian}
                        value={guardianRelation}
                        onChange={(e) => {
                          setGuardianRelation(e.target.value);
                          clearError("guardianRelation");
                        }}
                      />
                      {errors.guardianRelation && (
                        <div className="invalid-feedback">
                          {errors.guardianRelation}
                        </div>
                      )}
                    </div>
                    <div className="col-md-3 mb-3">
                      <label className="form-label">Mobile Number*</label>
                      <input
                        type="tel"
                        id="guardian-phone-input"
                        className={`form-control ${
                          errors.guardianPhoneNumber ? "is-invalid" : ""
                        }`}
                        required={hasGuardian}
                        value={guardianPhoneNumber}
                        onChange={(e) => {
                          setGuardianPhoneNumber(e.target.value);
                          clearError("guardianPhoneNumber");
                        }}
                      />
                      {errors.guardianPhoneNumber && (
                        <div className="invalid-feedback">
                          {errors.guardianPhoneNumber}
                        </div>
                      )}
                    </div>
                    <div className="col-md-3 mb-3">
                      <label className="form-label">Occupation*</label>
                      <input
                        type="text"
                        id="guardian-occupation-input"
                        className={`form-control ${
                          errors.guardianOccupation ? "is-invalid" : ""
                        }`}
                        required={hasGuardian}
                        value={guardianOccupation}
                        onChange={(e) => {
                          setGuardianOccupation(e.target.value);
                          clearError("guardianOccupation");
                        }}
                      />
                      {errors.guardianOccupation && (
                        <div className="invalid-feedback">
                          {errors.guardianOccupation}
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          {/* Siblings Information */}
          <div className="card mb-4">
            <div className="card-header bg-light">
              <h4 className="mb-0">Siblings Information</h4>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-12 mb-3">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="hasSiblings"
                      checked={hasSiblings}
                      onChange={(e) => setHasSiblings(e.target.checked)}
                    />
                    <label className="form-check-label" htmlFor="hasSiblings">
                      Has Siblings studying in this school
                    </label>
                  </div>
                </div>

                {hasSiblings && (
                  <>
                    {siblingsData.map((sibling, index) => (
                      <div key={index} className="col-12">
                        <div className="row">
                          <div className="col-md-3 mb-3">
                            <label className="form-label">Name*</label>
                            <input
                              type="text"
                              className="form-control"
                              required={hasSiblings}
                              value={sibling.name}
                              onChange={(e) =>
                                handleSiblingInputChange(
                                  index,
                                  "name",
                                  e.target.value
                                )
                              }
                            />
                          </div>
                          <div className="col-md-3 mb-3">
                            <label className="form-label">Class*</label>
                            <input
                              type="text"
                              className="form-control"
                              required={hasSiblings}
                              value={sibling.class}
                              onChange={(e) =>
                                handleSiblingInputChange(
                                  index,
                                  "class",
                                  e.target.value
                                )
                              }
                            />
                          </div>
                          <div className="col-md-3 mb-3">
                            <label className="form-label">Section*</label>
                            <input
                              type="text"
                              className="form-control"
                              required={hasSiblings}
                              value={sibling.section}
                              onChange={(e) =>
                                handleSiblingInputChange(
                                  index,
                                  "section",
                                  e.target.value
                                )
                              }
                            />
                          </div>
                          <div className="col-md-3 mb-3">
                            <label className="form-label">Relationship*</label>
                            <input
                              type="text"
                              className="form-control"
                              required={hasSiblings}
                              value={sibling.relation}
                              onChange={(e) =>
                                handleSiblingInputChange(
                                  index,
                                  "relation",
                                  e.target.value
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                    <div className="col-12">
                      <button
                        type="button"
                        className="btn btn-outline-primary"
                        onClick={addNewSibling}
                      >
                        Add Another Sibling
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          {/* Address */}
          <div className="card mb-4">
            <div className="card-header bg-light">
              <h4 className="mb-0">Address</h4>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-12 mb-3">
                  <label className="form-label">Current Address*</label>
                  <textarea
                    id="address-input"
                    className={`form-control ${
                      errors.address ? "is-invalid" : ""
                    }`}
                    rows={3}
                    required
                    value={address}
                    onChange={(e) => {
                      setAddress(e.target.value);
                      clearError("address");
                    }}
                  />
                  {errors.address && (
                    <div className="invalid-feedback">{errors.address}</div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Medical Information */}
          <div className="card mb-4">
            <div className="card-header bg-light">
              <h4 className="mb-0">Medical Information</h4>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6 mb-3">
                  <label className="form-label">Allergies (if any)</label>
                  <TagsInput
                    value={allergies}
                    onChange={setAllergies}
                    placeHolder="Enter allergies and press enter"
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">Medications (if any)</label>
                  <TagsInput
                    value={medications}
                    onChange={setMedications}
                    placeHolder="Enter medications and press enter"
                  />
                </div>
                <div className="col-12 mb-3">
                  <label className="form-label">Identification Marks*</label>
                  <textarea
                    id="identification-marks-input"
                    className={`form-control ${
                      errors.identificationMarks ? "is-invalid" : ""
                    }`}
                    rows={2}
                    required
                    value={identificationMarks}
                    onChange={(e) => {
                      setIdentificationMarks(e.target.value);
                      clearError("identificationMarks");
                    }}
                  />
                  {errors.identificationMarks && (
                    <div className="invalid-feedback">
                      {errors.identificationMarks}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Previous School */}
          <div className="card mb-4">
            <div className="card-header bg-light">
              <h4 className="mb-0">Previous School Details</h4>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-4 mb-3">
                  <label className="form-label">School Name*</label>
                  <input
                    type="text"
                    id="previous-school-name-input"
                    className={`form-control ${
                      errors.previousSchoolName ? "is-invalid" : ""
                    }`}
                    required
                    value={previousSchoolName}
                    onChange={(e) => {
                      setPreviousSchoolName(e.target.value);
                      clearError("previousSchoolName");
                    }}
                  />
                  {errors.previousSchoolName && (
                    <div className="invalid-feedback">
                      {errors.previousSchoolName}
                    </div>
                  )}
                </div>
                <div className="col-md-4 mb-3">
                  <label className="form-label">Period of Study*</label>
                  <input
                    type="text"
                    id="previous-school-period-input"
                    className={`form-control ${
                      errors.previousSchoolPeriodOfStudy ? "is-invalid" : ""
                    }`}
                    required
                    value={previousSchoolPeriodOfStudy}
                    onChange={(e) => {
                      setPreviousSchoolPeriodOfStudy(e.target.value);
                      clearError("previousSchoolPeriodOfStudy");
                    }}
                  />
                  {errors.previousSchoolPeriodOfStudy && (
                    <div className="invalid-feedback">
                      {errors.previousSchoolPeriodOfStudy}
                    </div>
                  )}
                </div>
                <div className="col-md-4 mb-3">
                  <label className="form-label">Class*</label>
                  <input
                    type="text"
                    id="previous-school-class-input"
                    className={`form-control ${
                      errors.previousSchoolClass ? "is-invalid" : ""
                    }`}
                    required
                    value={previousSchoolClass}
                    onChange={(e) => {
                      setPreviousSchoolClass(e.target.value);
                      clearError("previousSchoolClass");
                    }}
                  />
                  {errors.previousSchoolClass && (
                    <div className="invalid-feedback">
                      {errors.previousSchoolClass}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Submit Button */}
          <div className="mb-5">
            <div className="d-flex justify-content-center justify-content-md-end">
              <button
                type="submit"
                className="btn btn-primary btn-lg w-100 w-md-auto"
                style={{ minWidth: "200px" }}
                disabled={submitting}
              >
                {submitting ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm me-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Submitting...
                  </>
                ) : (
                  "Submit Application"
                )}
              </button>
            </div>
          </div>
        </form>
      </div>

      {/* Success Modal */}
      {showSuccessModal && (
        <div className="modal show d-block" tabIndex={-1}>
          <div
            className="modal-dialog modal-dialog-centered"
            style={{ zIndex: 1056 }}
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  Application Submitted Successfully!
                </h5>
              </div>
              <div className="modal-body">
                <p>Your application has been submitted successfully.</p>
                <p className="mb-0">
                  <strong>Application Number:</strong> {applicationNo}
                </p>
                <p className="text-muted small">
                  Please save this number for future reference.
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => window.location.reload()}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
          <div className="modal-backdrop show"></div>
        </div>
      )}
    </div>
  );
};

export default ApplyAdmission;
