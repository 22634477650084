import React, { useEffect, useState } from "react";
import { useApi } from "../../../utils/api";
import { useNotification } from "../../../core/hooks/useNotification";
import CommonSelect from "../../../core/common/commonSelect";

interface UpdateStatusModalProps {
  applicationId: string;
  status: "APPROVED" | "REJECTED";
  onClose: () => void;
  onUpdateStatus: (id: string, status: string, data: any) => Promise<void>;
}

export const UpdateStatusModal: React.FC<UpdateStatusModalProps> = ({
  applicationId,
  status,
  onClose,
  onUpdateStatus,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [comments, setComments] = useState("");
  const [rejectionReason, setRejectionReason] = useState("");
  const notification = useNotification();

  const handleSubmit = async () => {
    if (status === "REJECTED" && !rejectionReason.trim()) {
      notification.error("Rejection reason is required", {
        title: "Validation Error",
      });
      return;
    }

    try {
      setIsLoading(true);
      await onUpdateStatus(applicationId, status, {
        reviewComments: comments.trim(),
        ...(status === "REJECTED" && {
          rejectionReason: rejectionReason.trim(),
        }),
      });
      onClose();
    } catch (error) {
      console.error("Status update failed:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="text-start">
      {status === "REJECTED" && (
        <div className="alert alert-warning d-flex mb-4">
          <i className="ti ti-alert-circle me-2"></i>
          <div>Once rejected, the application cannot be approved later.</div>
        </div>
      )}

      <div className="mb-3">
        <label className="form-label">Comments</label>
        <textarea
          className="form-control"
          rows={3}
          value={comments}
          onChange={(e) => setComments(e.target.value)}
          placeholder="Enter your comments (optional)"
        />
      </div>

      {status === "REJECTED" && (
        <div className="mb-3">
          <label className="form-label">
            Rejection Reason <span className="text-danger">*</span>
          </label>
          <textarea
            className="form-control"
            rows={3}
            value={rejectionReason}
            onChange={(e) => setRejectionReason(e.target.value)}
            placeholder="Enter reason for rejection"
            required
          />
        </div>
      )}

      <div className="d-flex justify-content-end mt-4">
        <button
          className="btn btn-light me-2"
          onClick={onClose}
          disabled={isLoading}
        >
          Cancel
        </button>
        <button
          className={`btn btn-${status === "APPROVED" ? "success" : "danger"}`}
          onClick={handleSubmit}
          disabled={
            isLoading || (status === "REJECTED" && !rejectionReason.trim())
          }
        >
          {isLoading ? (
            <>
              <span
                className="spinner-border spinner-border-sm me-2"
                role="status"
                aria-hidden="true"
              ></span>
              Processing...
            </>
          ) : status === "APPROVED" ? (
            "Approve Application"
          ) : (
            "Reject Application"
          )}
        </button>
      </div>
    </div>
  );
};

interface ConvertToStudentModalProps {
  applicationId: string;
  onClose: () => void;
  onConvert: (id: string, data: any) => Promise<void>;
}

export const ConvertToStudentModal: React.FC<ConvertToStudentModalProps> = ({
  applicationId,
  onClose,
  onConvert,
}) => {
  const { AxiosGetWithToken } = useApi();
  const notification = useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedSection, setSelectedSection] = useState("");

  const [classes, setClasses] = useState<
    Array<{
      _id: string;
      name: string;
      sections: Array<{
        _id: string;
        name: string;
      }>;
    }>
  >([]);

  useEffect(() => {
    fetchClasses();
  }, []);

  const fetchClasses = async () => {
    try {
      const response = await AxiosGetWithToken("/class");
      setClasses(response);
    } catch (error) {
      console.error("Failed to fetch classes:", error);
      notification.error("Failed to fetch classes", {
        title: "Error",
      });
    }
  };

  const handleSubmit = async () => {
    if (!selectedClass || !selectedSection) {
      notification.error("Please select both class and section", {
        title: "Validation Error",
      });
      return;
    }

    try {
      setIsLoading(true);
      await onConvert(applicationId, {
        classId: selectedClass,
        sectionId: selectedSection,
      });
      onClose();
    } catch (error) {
      console.error("Conversion failed:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const selectedClassData = classes.find((c) => c._id === selectedClass);

  return (
    <div className="text-start">
      <div className="alert alert-info d-flex mb-4">
        <i className="ti ti-info-circle me-2"></i>
        <div>
          Converting to student will create a new student record and mark the
          application as converted.
        </div>
      </div>

      <div className="mb-3">
        <label className="form-label">
          Class <span className="text-danger">*</span>
        </label>
        <CommonSelect
          className="select"
          options={classes.map((c) => ({
            label: c.name,
            value: c._id,
          }))}
          value={selectedClass}
          onChange={(option) => {
            setSelectedClass(option?.value || "");
            setSelectedSection(""); // Reset section when class changes
          }}
        />
      </div>

      <div className="mb-3">
        <label className="form-label">
          Section <span className="text-danger">*</span>
        </label>
        <CommonSelect
          className="select"
          options={
            selectedClassData?.sections.map((s) => ({
              label: s.name,
              value: s._id,
            })) || []
          }
          value={selectedSection}
          onChange={(option) => setSelectedSection(option?.value || "")}
        />
      </div>

      <div className="d-flex justify-content-end mt-4">
        <button
          className="btn btn-light me-2"
          onClick={onClose}
          disabled={isLoading}
        >
          Cancel
        </button>
        <button
          className="btn btn-primary"
          onClick={handleSubmit}
          disabled={isLoading || !selectedClass || !selectedSection}
        >
          {isLoading ? (
            <>
              <span
                className="spinner-border spinner-border-sm me-2"
                role="status"
                aria-hidden="true"
              ></span>
              Converting...
            </>
          ) : (
            "Convert to Student"
          )}
        </button>
      </div>
    </div>
  );
};
