import React, { useState } from "react";
import { useNotification } from "../../../core/hooks/useNotification";

interface ActivateModalProps {
  cycleId: string;
  onActivate: (id: string) => Promise<void>;
  onClose: () => void;
}

export const ActivateModalContent: React.FC<ActivateModalProps> = ({
  cycleId,
  onActivate,
  onClose,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const notification = useNotification();

  const handleActivate = async () => {
    try {
      setIsLoading(true);
      //   await onActivate(cycleId);
      notification.success("Admission cycle activated successfully", {
        title: "Activation Success",
        duration: 5000,
      });
      onClose();
    } catch (error) {
      const errorMessage =
        error instanceof Error
          ? error.message
          : "Failed to activate admission cycle";
      notification.error(errorMessage, {
        title: "Activation Error",
        duration: 7000,
      });
      console.error("Failed to activate cycle:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="text-start">
      <p className="mb-3">
        Are you sure you want to activate this admission cycle?
      </p>
      <div className="bg-light rounded border p-3">
        <div className="text-black fw-medium mb-2">Once activated:</div>
        <div>
          <div className="mb-1">Fields except end date cannot be modified.</div>
          <div className="mb-1">Will start receiving applications.</div>
          <div>The cycle status cannot be reverted back to draft</div>
        </div>
      </div>
      <div className="d-flex justify-content-end mt-4">
        <button
          className="btn btn-light me-2"
          onClick={onClose}
          disabled={isLoading}
        >
          Cancel
        </button>
        <button
          className="btn btn-success"
          onClick={handleActivate}
          disabled={isLoading}
        >
          {isLoading ? (
            <>
              <span
                className="spinner-border spinner-border-sm me-2"
                role="status"
                aria-hidden="true"
              ></span>
              Activating...
            </>
          ) : (
            "Activate Cycle"
          )}
        </button>
      </div>
    </div>
  );
};
