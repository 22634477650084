import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { AlertCircle, CheckCircle2, Info, XCircle, X } from "lucide-react";
import {
  useNotification,
  useNotificationState,
} from "../hooks/useNotification";

const Toast = () => {
  const { notifications } = useNotificationState();
  const notification = useNotification();

  const getIcon = (type: string) => {
    switch (type) {
      case "success":
        return <CheckCircle2 size={20} />;
      case "error":
        return <XCircle size={20} />;
      case "warning":
        return <AlertCircle size={20} />;
      case "info":
        return <Info size={20} />;
      default:
        return <Info size={20} />;
    }
  };

  const getToastClass = (type: string) => {
    switch (type) {
      case "success":
        return {
          bg: "bg-white",
          icon: "text-success",
          border: "border-success",
          progress: "bg-success",
        };
      case "error":
        return {
          bg: "bg-white",
          icon: "text-danger",
          border: "border-danger",
          progress: "bg-danger",
        };
      case "warning":
        return {
          bg: "bg-white",
          icon: "text-warning",
          border: "border-warning",
          progress: "bg-warning",
        };
      case "info":
        return {
          bg: "bg-white",
          icon: "text-info",
          border: "border-info",
          progress: "bg-info",
        };
      default:
        return {
          bg: "bg-white",
          icon: "text-primary",
          border: "border-primary",
          progress: "bg-primary",
        };
    }
  };

  return (
    <div
      className="position-fixed"
      style={{ top: "1rem", right: "1rem", zIndex: 1100 }}
    >
      <AnimatePresence mode="sync">
        {notifications.map((toast, index) => {
          const colors = getToastClass(toast.type);

          return (
            <motion.div
              key={toast.id}
              initial={{ opacity: 0, x: 100, scale: 0.9 }}
              animate={{ opacity: 1, x: 0, scale: 1 }}
              exit={{ opacity: 0, x: 100, scale: 0.9 }}
              className={`${colors.bg} rounded-3 border-start border-4 ${colors.border} shadow-hover`}
              style={{
                width: "380px",
                cursor: "pointer",
                position: "relative",
                overflow: "hidden",
                zIndex: 9999 - index,
                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.08)",
                marginBottom: "0.75rem",
                transition: "box-shadow 0.2s ease",
              }}
            >
              <div className="p-3">
                <div className="d-flex align-items-start">
                  <div className={`flex-shrink-0 ${colors.icon}`}>
                    {getIcon(toast.type)}
                  </div>
                  <div className="ms-3 flex-grow-1 text-dark">
                    {toast.title && (
                      <div
                        className="fw-semibold mb-1"
                        style={{
                          fontSize: "0.9375rem",
                          letterSpacing: "-0.01em",
                        }}
                      >
                        {toast.title}
                      </div>
                    )}
                    <div
                      style={{
                        fontSize: "0.875rem",
                        color: "#4B5563",
                        lineHeight: "1.4",
                      }}
                    >
                      {toast.message}
                    </div>
                  </div>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      notification.close(toast.id);
                    }}
                    style={{
                      color: "#98a2b3",
                      transition: "opacity 0.2s ease",
                      opacity: 0.75,
                      border: "none",
                      background: "none",
                      padding: 0,
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onMouseOver={(e) => (e.currentTarget.style.opacity = "1")}
                    onMouseOut={(e) => (e.currentTarget.style.opacity = "0.75")}
                  >
                    <X size={16} />
                  </button>
                </div>
              </div>

              {/* Progress bar */}
              <motion.div
                initial={{ width: "100%" }}
                animate={{ width: "0%" }}
                transition={{
                  duration: (toast.duration || 5000) / 1000,
                  ease: "linear",
                }}
                onAnimationComplete={() => notification.close(toast.id)}
                className={colors.progress}
                style={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  height: "3px",
                  opacity: 0.7,
                }}
              />
            </motion.div>
          );
        })}
      </AnimatePresence>

      <style>
        {`
          .shadow-hover {
            transition: box-shadow 0.2s ease, transform 0.2s ease;
          }
          .shadow-hover:hover {
            box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
            transform: translateY(-1px);
          }
        `}
      </style>
    </div>
  );
};

export default Toast;
