import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { DatePicker } from "antd";
import CommonSelect from "../../../core/common/commonSelect";
import dayjs from "dayjs";
import { useApi } from "../../../utils/api";
import Invoice from "./Invoice";

// Types
type FeeType =
  | "Application Fee"
  | "Tuition Fee"
  | "Term Fee"
  | "Uniform Fee"
  | "Books & Note Books Fee"
  | "Shoe / Socks / belt Fee"
  | "Miscellaneous"
  | "Van Fee"
  | "Fine"
  | "Others";

type PaymentMode = "cash" | "cheque" | "online";

interface Option {
  label: string;
  value: string;
}

interface FeeParticular {
  description: FeeType;
  amount: number;
  notes?: string;
}

interface PaymentDetails {
  mode: PaymentMode;
  chequeNo?: string;
  bankName?: string;
  transactionId?: string;
  transactionDate?: Date;
}

interface StudentDetails {
  name: string;
  class: string;
  section: string;
  rollNo: string;
  contactNumber: string;
}

interface Props {
  rollNo?: string;
}

interface SearchResult {
  name: string;
  class: string;
  section: string;
  contactNumber: string;
}

// Utility functions
const formatIndianNumber = (num: number): string => {
  const parts = num.toFixed(2).split(".");
  const integerPart = parts[0];
  const decimalPart = parts[1];

  // Add commas for thousands and above
  const lastThree = integerPart.substring(integerPart.length - 3);
  const otherNumbers = integerPart.substring(0, integerPart.length - 3);
  const formatted =
    otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
    (otherNumbers ? "," : "") +
    lastThree;

  return `₹${formatted}.${decimalPart}`;
};

// Number to words conversion
const singleDigits = [
  "Zero",
  "One",
  "Two",
  "Three",
  "Four",
  "Five",
  "Six",
  "Seven",
  "Eight",
  "Nine",
];
const twoDigits = [
  "Ten",
  "Eleven",
  "Twelve",
  "Thirteen",
  "Fourteen",
  "Fifteen",
  "Sixteen",
  "Seventeen",
  "Eighteen",
  "Nineteen",
];
const tensMultiple = [
  "",
  "",
  "Twenty",
  "Thirty",
  "Forty",
  "Fifty",
  "Sixty",
  "Seventy",
  "Eighty",
  "Ninety",
];

function getWords(num: number): string {
  if (num < 20) return num < 10 ? singleDigits[num] : twoDigits[num - 10];

  if (num < 100) {
    const words = tensMultiple[Math.floor(num / 10)];
    return num % 10 > 0 ? `${words} ${singleDigits[num % 10]}` : words;
  }
  return "";
}

function convertAmountToWords(amount: number): string {
  if (amount === 0) return "Rupees Zero Only";

  let rupees = Math.floor(amount);
  const paise = Math.round((amount - rupees) * 100);

  let result = "";

  if (rupees > 0) {
    // Handle Crores
    const crore = Math.floor(rupees / 10000000);
    if (crore > 0) {
      result += getWords(crore) + " Crore ";
      rupees %= 10000000;
    }

    // Handle Lakhs
    const lakh = Math.floor(rupees / 100000);
    if (lakh > 0) {
      result += getWords(lakh) + " Lakh ";
      rupees %= 100000;
    }

    // Handle Thousands
    const thousand = Math.floor(rupees / 1000);
    if (thousand > 0) {
      result += getWords(thousand) + " Thousand ";
      rupees %= 1000;
    }

    // Handle Hundreds
    const hundred = Math.floor(rupees / 100);
    if (hundred > 0) {
      result += getWords(hundred) + " Hundred ";
      rupees %= 100;
    }

    // Handle remaining two digits
    if (rupees > 0) {
      if (result !== "") result += "and ";
      result += getWords(rupees) + " ";
    }

    result = "Rupees " + result.trim();
  }

  // Add paise if exists
  if (paise > 0) {
    result += " and " + getWords(paise) + " Paise";
  }

  return result + " Only";
}

// Constants
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const feeTypes: { label: string; value: FeeType }[] = [
  { label: "Application Fee", value: "Application Fee" },
  { label: "Tuition Fee", value: "Tuition Fee" },
  { label: "Term Fee", value: "Term Fee" },
  { label: "Uniform Fee", value: "Uniform Fee" },
  { label: "Books & Note Books Fee", value: "Books & Note Books Fee" },
  { label: "Shoe / Socks / belt Fee", value: "Shoe / Socks / belt Fee" },
  { label: "Miscellaneous", value: "Miscellaneous" },
  { label: "Van Fee", value: "Van Fee" },
  { label: "Fine", value: "Fine" },
  { label: "Others", value: "Others" },
];

const paymentModes: { label: string; value: PaymentMode }[] = [
  { label: "Cash", value: "cash" },
  { label: "Cheque", value: "cheque" },
  { label: "Online", value: "online" },
];

const FeeCollectionModal: React.FC<Props> = ({ rollNo }) => {
  // State
  const [particulars, setParticulars] = useState<FeeParticular[]>([
    {
      description: "Tuition Fee",
      amount: 0,
      notes: "",
    },
  ]);
  const [payment, setPayment] = useState<PaymentDetails>({
    mode: "cash",
  });
  const [selectedMonth, setSelectedMonth] = useState(
    months[new Date().getMonth()]
  );
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalInWords, setTotalInWords] = useState("");
  const [studentDetails, setStudentDetails] = useState<StudentDetails | null>(
    null
  );
  const [errors, setErrors] = useState<{
    rollNo?: string;
    particulars?: string;
    payment?: string;
    general?: string;
  }>({});

  const [searchText, setSearchText] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [searchResult, setSearchResult] = useState<SearchResult | null>(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const searchInputRef = useRef<HTMLDivElement>(null);
  const { AxiosGetWithToken, AxiosPostWithToken } = useApi();

  const [showPreview, setShowPreview] = useState(false);

  const [printSignal, setPrintSignal] = useState(0);
  const [downloadSignal, setDownloadSignal] = useState(0);

  const [feeReceipt, setFeeReceipt] = useState<{
    serialNumber?: string;
    feeId?: string;
  } | null>(null);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handlePrint = () => {
    setPrintSignal((prev) => prev + 1);
  };

  const handleDownload = () => {
    setDownloadSignal((prev) => prev + 1);
  };

  useEffect(() => {
    calculateTotal();
  }, [particulars]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        searchInputRef.current &&
        !searchInputRef.current.contains(event.target as Node)
      ) {
        setShowDropdown(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSearch = async () => {
    if (!searchText.trim()) return;

    setIsSearching(true);
    setShowDropdown(true);

    try {
      const response = await AxiosGetWithToken(`/student/basic/${searchText}`);
      if (response?.student) {
        setSearchResult(response.student);
        setErrors((prev) => ({ ...prev, rollNo: undefined }));
      }
    } catch (error) {
      setSearchResult(null);
      setErrors((prev) => ({
        ...prev,
        rollNo: "Student not found",
      }));
    } finally {
      setIsSearching(false);
    }
  };

  // Add selection handler
  const handleSelectStudent = (result: SearchResult) => {
    setStudentDetails({
      name: result.name,
      class: result.class,
      section: result.section,
      rollNo: searchText,
      contactNumber: result.contactNumber,
    });
    setShowDropdown(false);
  };

  // Handlers
  const handleMonthChange = (option: Option | null) => {
    if (option) {
      setSelectedMonth(option.value);
    }
  };

  const handlePaymentModeChange = (option: Option | null) => {
    if (option) {
      const mode = option.value as PaymentMode;
      setPayment({
        mode,
        chequeNo: mode === "cheque" ? payment.chequeNo : undefined,
        bankName: mode === "cheque" ? payment.bankName : undefined,
        transactionId: mode === "online" ? payment.transactionId : undefined,
        transactionDate:
          mode === "cheque" ? payment.transactionDate : undefined,
      });
      setErrors((prev) => ({ ...prev, payment: undefined }));
    }
  };

  const handleFeeTypeChange = (option: Option | null, index: number) => {
    if (option) {
      updateParticular(index, "description", option.value as FeeType);
    }
  };

  const handleAmountChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const inputValue = e.target.value;

    // Store the raw input value first
    setParticulars((prevParticulars) => {
      const newParticulars = [...prevParticulars];
      newParticulars[index] = {
        ...newParticulars[index],
        amount: inputValue === "" ? 0 : parseFloat(inputValue),
      };
      return newParticulars;
    });
  };

  const addParticular = () => {
    setParticulars([
      ...particulars,
      {
        description: "Tuition Fee",
        amount: 0,
        notes: "",
      },
    ]);
  };

  const removeParticular = (index: number) => {
    if (particulars.length > 1) {
      setParticulars(particulars.filter((_, i) => i !== index));
    }
  };

  const updateParticular = (
    index: number,
    field: keyof FeeParticular,
    value: any
  ) => {
    const updatedParticulars = [...particulars];
    updatedParticulars[index] = {
      ...updatedParticulars[index],
      [field]: value,
    };
    setParticulars(updatedParticulars);
    setErrors((prev) => ({ ...prev, particulars: undefined }));
  };

  const calculateTotal = () => {
    const total = particulars.reduce(
      (sum, item) => sum + (item.amount || 0),
      0
    );
    setTotalAmount(total);
    setTotalInWords(convertAmountToWords(total));
  };

  const validateForm = (): boolean => {
    const newErrors: typeof errors = {};

    if (!rollNo && !studentDetails) {
      newErrors.rollNo = "Student details are required";
    }

    if (particulars.length === 0) {
      newErrors.particulars = "At least one fee particular is required";
    }

    if (particulars.some((p) => !p.description || p.amount <= 0)) {
      newErrors.particulars =
        "All fee particulars must have a type and valid amount";
    }

    if (
      payment.mode === "cheque" &&
      (!payment.chequeNo || !payment.bankName || !payment.transactionDate)
    ) {
      newErrors.payment = "All cheque details are required";
    }

    if (payment.mode === "online" && !payment.transactionId) {
      newErrors.payment = "Transaction ID is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const getInvoiceData = () => {
    return {
      studentName: studentDetails?.name || "",
      grade: studentDetails?.class || "",
      rollNo: studentDetails?.rollNo || "",
      month: selectedMonth,
      items: particulars.map((p, index) => ({
        id: index + 1,
        name: p.description,
        amount: p.amount,
      })),
      totalInWords: totalInWords,
    };
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true);

    try {
      const submitData = {
        rollNo: studentDetails?.rollNo || rollNo || "",
        particulars: particulars.map((item) => ({
          description: item.description,
          amount: item.amount,
          notes: item.notes || "",
        })),
        totalAmount,
        totalInWords,
        payment: {
          mode: payment.mode,
          ...(payment.mode === "cheque" && {
            chequeNo: payment.chequeNo,
            bankName: payment.bankName,
            transactionDate: payment.transactionDate,
          }),
          ...(payment.mode === "online" && {
            transactionId: payment.transactionId,
          }),
        },
        month: selectedMonth,
      };

      const response = await AxiosPostWithToken("/fees", submitData);

      if (response.feeId) {
        const { serialNumber, feeId } = response;

        // Store fee receipt details
        setFeeReceipt({ serialNumber, feeId });

        // Show the invoice preview
        setShowPreview(true);

        // Trigger print after a small delay to ensure Invoice is rendered
        // setTimeout(() => {
        //   handlePrint();
        // }, 100);
      }
    } catch (error) {
      setErrors((prev) => ({
        ...prev,
        general: "Failed to submit fee details. Please try again.",
      }));
      console.error("Fee collection failed:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const getModalContainer = () => {
    const modalElement = document.getElementById("modal-datepicker");
    return modalElement ? modalElement : document.body;
  };

  // Reset function to clear all state
  const resetModalState = () => {
    setParticulars([
      {
        description: "Tuition Fee",
        amount: 0,
        notes: "",
      },
    ]);
    setPayment({ mode: "cash" });
    setSelectedMonth(months[new Date().getMonth()]);
    setTotalAmount(0);
    setTotalInWords("");
    setStudentDetails(null);
    setErrors({});
    setSearchText("");
    setIsSearching(false);
    setSearchResult(null);
    setShowDropdown(false);
    setShowPreview(false);
    setPrintSignal(0);
    setDownloadSignal(0);
    setFeeReceipt(null);
  };

  // Add event listeners for modal close
  useEffect(() => {
    const modal = document.getElementById("add_fees_collect");

    const handleModalClose = () => {
      resetModalState();
    };

    if (modal) {
      modal.addEventListener("hidden.bs.modal", handleModalClose);

      return () => {
        modal.removeEventListener("hidden.bs.modal", handleModalClose);
      };
    }
  }, []);

  return (
    <div className="modal fade" id="add_fees_collect">
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <div className="w-100">
              <h4 className="modal-title ">New Fees</h4>
            </div>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={resetModalState}
            >
              <i className="ti ti-x" />
            </button>
          </div>

          <div className="modal-body">
            {errors.general && (
              <div className="alert alert-danger py-2 mb-3">
                {errors.general}
              </div>
            )}
            {showPreview ? (
              <Invoice
                {...getInvoiceData()}
                serialNumber={feeReceipt?.serialNumber}
                printSignal={printSignal}
                downloadSignal={downloadSignal}
              />
            ) : (
              <>
                {studentDetails && (
                  <div className="mb-2">
                    <div className="bg-light-300 border rounded-3 p-3">
                      <div className="row align-items-center g-2">
                        <div className="col">
                          <h5 className="mb-1">{studentDetails.name}</h5>
                          <div className="d-flex align-items-center gap-3">
                            <span className="text-muted">
                              Grade: {studentDetails.class} -{" "}
                              {studentDetails.section}
                            </span>
                            {studentDetails.contactNumber && (
                              <>
                                <span className="text-muted">•</span>
                                <span className="text-muted">
                                  <i className="ti ti-phone me-1"></i>
                                  {studentDetails.contactNumber}
                                </span>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="row mb-4">
                  {!rollNo && (
                    <div className="col-md-6" ref={searchInputRef}>
                      <label className="form-label">Roll Number</label>
                      <div className="position-relative">
                        <div
                          className={`input-group ${
                            errors.rollNo ? "is-invalid" : ""
                          }`}
                        >
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Roll Number"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                            onKeyPress={(e) => {
                              if (e.key === "Enter") {
                                handleSearch();
                              }
                            }}
                          />
                          <button
                            className="btn btn-primary"
                            type="button"
                            onClick={handleSearch}
                          >
                            <i className="ti ti-search"></i>
                          </button>
                        </div>
                        {errors.rollNo && (
                          <div className="invalid-feedback">
                            {errors.rollNo}
                          </div>
                        )}

                        {/* Search Dropdown */}
                        {showDropdown && (
                          <div className="position-absolute top-100 start-0 w-100 mt-1 shadow-sm bg-white border rounded-2 z-3">
                            {isSearching ? (
                              <div className="p-3 text-center text-muted">
                                <div
                                  className="spinner-border spinner-border-sm me-2"
                                  role="status"
                                >
                                  <span className="visually-hidden">
                                    Searching...
                                  </span>
                                </div>
                                Searching...
                              </div>
                            ) : searchResult ? (
                              <div
                                className="p-3 hover-bg-light cursor-pointer"
                                onClick={() =>
                                  handleSelectStudent(searchResult)
                                }
                                role="button"
                              >
                                <div>
                                  <div className="fw-medium mb-1">
                                    {searchResult.name}
                                  </div>
                                  <div className="d-flex align-items-center gap-3 text-muted small">
                                    <span>
                                      Grade: {searchResult.class} -{" "}
                                      {searchResult.section}
                                    </span>
                                    <span>•</span>
                                    <span>
                                      <i className="ti ti-phone me-1"></i>
                                      {searchResult.contactNumber}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="p-3 text-center text-muted">
                                No results found
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="col-md-6">
                    <label className="form-label">Month</label>
                    <CommonSelect
                      options={months.map((month) => ({
                        label: month,
                        value: month,
                      }))}
                      value={selectedMonth}
                      onChange={handleMonthChange}
                    />
                  </div>
                </div>

                {/* Fee Particulars */}
                <div className="mb-4">
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <h5 className="mb-0">Fee Particulars</h5>
                    <button
                      type="button"
                      className="btn btn-primary btn-sm"
                      onClick={addParticular}
                    >
                      <i className="ti ti-plus me-1"></i>
                      New Fee
                    </button>
                  </div>

                  {errors.particulars && (
                    <div className="alert alert-danger py-2">
                      {errors.particulars}
                    </div>
                  )}

                  <div className="border rounded-3 p-3">
                    {particulars.map((particular, index) => (
                      <div
                        key={index}
                        className={`row ${index > 0 ? "mt-3" : ""}`}
                      >
                        <div className="col-md-4">
                          <CommonSelect
                            options={feeTypes}
                            value={particular.description}
                            onChange={(option) =>
                              handleFeeTypeChange(option, index)
                            }
                            defaultValue={feeTypes[0]}
                          />
                        </div>
                        <div className="col-md-3">
                          <input
                            type="text"
                            className="form-control text-end"
                            placeholder="0.00"
                            value={
                              particular.amount === 0
                                ? ""
                                : particular.amount.toString()
                            }
                            onChange={(e) => handleAmountChange(e, index)}
                            onBlur={(e) => {
                              // Format only when input loses focus
                              const value = parseFloat(e.target.value) || 0;
                              setParticulars((prevParticulars) => {
                                const newParticulars = [...prevParticulars];
                                newParticulars[index] = {
                                  ...newParticulars[index],
                                  amount: value,
                                };
                                return newParticulars;
                              });
                            }}
                          />
                        </div>
                        <div className="col-md-4">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Notes (optional)"
                            value={particular.notes}
                            onChange={(e) =>
                              updateParticular(index, "notes", e.target.value)
                            }
                          />
                        </div>
                        <div className="col-md-1 d-flex align-items-center">
                          {particulars.length > 1 && (
                            <button
                              type="button"
                              className="btn btn-link text-danger p-0"
                              onClick={() => removeParticular(index)}
                            >
                              <i className="ti ti-trash" />
                            </button>
                          )}
                        </div>
                      </div>
                    ))}

                    {/* Total Amount Summary */}
                    <div className="row mt-3 pt-3 border-top">
                      <div className="col-12">
                        <div className="d-flex justify-content-end align-items-center">
                          <h5 className="mb-0">
                            Total: {formatIndianNumber(totalAmount)}
                          </h5>
                        </div>
                        <p className="text-muted text-end mb-0 small">
                          {totalInWords}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Payment Details */}
                <div className="mb-4">
                  <h5 className="mb-3">Payment Details</h5>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label className="form-label">Payment Mode</label>
                      <CommonSelect
                        options={paymentModes}
                        value={payment.mode}
                        onChange={handlePaymentModeChange}
                      />
                    </div>
                  </div>

                  {errors.payment && (
                    <div className="alert alert-danger py-2 mb-3">
                      {errors.payment}
                    </div>
                  )}

                  {payment.mode === "cheque" && (
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <label className="form-label">Cheque Number</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Cheque Number"
                          value={payment.chequeNo}
                          onChange={(e) =>
                            setPayment({ ...payment, chequeNo: e.target.value })
                          }
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label className="form-label">Bank Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Bank Name"
                          value={payment.bankName}
                          onChange={(e) =>
                            setPayment({ ...payment, bankName: e.target.value })
                          }
                        />
                      </div>
                      <div className="col-md-6">
                        <label className="form-label">Transaction Date</label>
                        <div className="position-relative">
                          <DatePicker
                            className="form-control"
                            getPopupContainer={getModalContainer}
                            onChange={(date) =>
                              setPayment({
                                ...payment,
                                transactionDate: date?.toDate(),
                              })
                            }
                            format="DD-MM-YYYY"
                            placeholder="Select Date"
                          />
                          <i className="ti ti-calendar position-absolute top-50 end-3 translate-middle-y"></i>
                        </div>
                      </div>
                    </div>
                  )}

                  {payment.mode === "online" && (
                    <div className="row">
                      <div className="col-md-6">
                        <label className="form-label">Transaction ID</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Transaction ID"
                          value={payment.transactionId}
                          onChange={(e) =>
                            setPayment({
                              ...payment,
                              transactionId: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  )}
                </div>

                {/* Notes or Additional Information if needed */}
                <div className="mb-3">
                  <label className="form-label">Notes (Optional)</label>
                  <textarea
                    className="form-control"
                    rows={3}
                    placeholder="Enter any additional notes"
                  ></textarea>
                </div>
              </>
            )}
          </div>

          <div className="modal-footer">
            {showPreview && feeReceipt ? (
              // After successful submission, show only print button
              <div>
                <button
                  type="button"
                  className="btn btn-secondary me-2"
                  onClick={handleDownload}
                >
                  <i className="ti ti-download me-1"></i>
                  Download Receipt
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handlePrint}
                >
                  <i className="ti ti-printer me-1"></i>
                  Print Receipt
                </button>
              </div>
            ) : (
              // Show regular buttons during form editing/preview
              <>
                {/* <button
                  type="button"
                  className="btn btn-light me-2"
                  data-bs-dismiss="modal"
                  onClick={resetModalState}
                >
                  Cancel
                </button> */}
                <button
                  type="button"
                  className="btn btn-secondary me-2"
                  onClick={() => setShowPreview(!showPreview)}
                >
                  {showPreview ? "Edit" : "Preview"}
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm me-1"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Saving...
                    </>
                  ) : (
                    "Save"
                  )}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeeCollectionModal;
