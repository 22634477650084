import { QRCodeSVG } from "qrcode.react";
import { useEffect } from "react";

interface ModalProps {
  id: string;
  modalSize?: string;
  onClose: () => void;
  title: string;
  showQR?: boolean;
  showLink?: boolean;
  qrValue?: string;
  linkValue?: string;
  children?: React.ReactNode;
}

const CustomModal: React.FC<ModalProps> = ({
  id,
  modalSize = "",
  onClose,
  title,
  showQR = false,
  showLink = false,
  qrValue = "",
  linkValue = "",
  children,
}) => {
  const handleCopyUrl = async () => {
    try {
      await navigator.clipboard.writeText(linkValue);
      // Add toast notification if needed
    } catch (err) {
      console.error("Failed to copy:", err);
    }
  };

  const handleDownloadQR = () => {
    const svg = document.querySelector(".qr-code-svg");
    if (svg) {
      const svgData = new XMLSerializer().serializeToString(svg);
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const img = new Image();

      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx?.drawImage(img, 0, 0);
        const pngFile = canvas.toDataURL("image/png");

        const downloadLink = document.createElement("a");
        downloadLink.download = "admission-qr.png";
        downloadLink.href = pngFile;
        downloadLink.click();
      };

      img.src = "data:image/svg+xml;base64," + btoa(svgData);
    }
  };

  // useEffect(() => {
  //   const modalEl = document.getElementById(id);
  //   if (modalEl && modalEl.classList.contains("show")) {
  //     // Force a DOM update by toggling a class
  //     modalEl.classList.add("content-updating");
  //     setTimeout(() => {
  //       modalEl.classList.remove("content-updating");
  //     }, 0);
  //   }
  // }, [children, id]);

  return (
    <div className="modal fade" id={id}>
      <div className={`modal-dialog modal-dialog-centered ${modalSize}`}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{title}</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body text-center">
            {showQR && qrValue && (
              <div className="mb-4">
                <QRCodeSVG
                  value={qrValue}
                  size={200}
                  level="H"
                  className="qr-code-svg"
                  includeMargin={true}
                />
                <button
                  className="btn btn-outline-primary mt-3"
                  onClick={handleDownloadQR}
                  type="button"
                >
                  <i className="ti ti-download me-2"></i>
                  Download QR Code
                </button>
              </div>
            )}

            {showLink && linkValue && (
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  value={linkValue}
                  readOnly
                  aria-label="Link Value"
                />
                <button
                  className="btn btn-primary"
                  onClick={handleCopyUrl}
                  type="button"
                >
                  <i className="ti ti-copy"></i>
                </button>
              </div>
            )}

            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomModal;
