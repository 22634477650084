import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type NotificationType = "success" | "error" | "warning" | "info";

export interface Notification {
  id: string;
  type: NotificationType;
  message: string;
  title?: string;
  duration?: number;
  createdAt: number;
}

type NotificationPayload = Omit<Notification, "id" | "createdAt">;

interface NotificationState {
  notifications: Notification[];
  queue: Notification[];
  maxVisible: number;
  lastAddedNotification?: Notification; // Optional field for tracking last added
}

const initialState: NotificationState = {
  notifications: [],
  queue: [],
  maxVisible: 3,
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    addNotification: (state, action: PayloadAction<NotificationPayload>) => {
      const newNotification = {
        ...action.payload,
        id: Date.now().toString(),
        createdAt: Date.now(),
      };

      if (state.notifications.length < state.maxVisible) {
        state.notifications.push(newNotification);
      } else {
        state.queue.push(newNotification);
      }

      // Store the last added notification without returning a new state
      state.lastAddedNotification = newNotification;
    },
    removeNotification: (state, action: PayloadAction<string>) => {
      state.notifications = state.notifications.filter(
        (notification) => notification.id !== action.payload
      );

      if (
        state.queue.length > 0 &&
        state.notifications.length < state.maxVisible
      ) {
        const [nextNotification, ...remainingQueue] = state.queue;
        state.notifications.push(nextNotification);
        state.queue = remainingQueue;
      }
    },
    clearAllNotifications: (state) => {
      state.notifications = [];
      state.queue = [];
    },
    setMaxVisible: (state, action: PayloadAction<number>) => {
      state.maxVisible = action.payload;
    },
  },
});

export const {
  addNotification,
  removeNotification,
  clearAllNotifications,
  setMaxVisible,
} = notificationSlice.actions;

export default notificationSlice.reducer;
