import axios from "axios";
import { useAuth } from "../context/AuthProvider";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true, // Required for CSRF
});

export const useApi = () => {
  const { accessToken, refreshAccessToken, logout } = useAuth();

  const getCsrfToken = async () => {
    try {
      const response = await api.get("/users/csrf-token", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.data.csrfToken;
    } catch (error) {
      console.error("Failed to get CSRF token:", error);
      throw error;
    }
  };

  const handleAuthError = async (error: any) => {
    if (error.response && error.response.status === 401) {
      try {
        const newAccessToken = await refreshAccessToken();
        const originalRequest = error.config;
        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
        return await api(originalRequest);
      } catch (refreshError) {
        logout();
        throw refreshError;
      }
    }
    throw error;
  };

  const AxiosPostWithToken = async (
    route: string,
    data: any,
    requiresCsrf = false
  ) => {
    try {
      const headers: Record<string, string> = {
        Authorization: `Bearer ${accessToken}`,
      };

      if (requiresCsrf) {
        const csrfToken = await getCsrfToken();
        headers["CSRF-Token"] = csrfToken;
      }

      const response = await api.post(route, data, { headers });
      return response.data;
    } catch (error) {
      return await handleAuthError(error);
    }
  };

  const AxiosGetWithToken = async (
    route: string,
    params?: any,
    requiresCsrf = false
  ) => {
    try {
      const headers: Record<string, string> = {
        Authorization: `Bearer ${accessToken}`,
      };

      if (requiresCsrf) {
        const csrfToken = await getCsrfToken();
        headers["CSRF-Token"] = csrfToken;
      }

      const response = await api.get(route, {
        headers,
        params,
      });
      return response.data;
    } catch (error) {
      return await handleAuthError(error);
    }
  };

  const AxiosPutWithToken = async (
    route: string,
    data: any,
    requiresCsrf = false
  ) => {
    try {
      const headers: Record<string, string> = {
        Authorization: `Bearer ${accessToken}`,
      };

      if (requiresCsrf) {
        const csrfToken = await getCsrfToken();
        headers["CSRF-Token"] = csrfToken;
      }

      const response = await api.put(route, data, { headers });
      return response.data;
    } catch (error) {
      return await handleAuthError(error);
    }
  };

  const AxiosDeleteWithToken = async (route: string, requiresCsrf = false) => {
    try {
      const headers: Record<string, string> = {
        Authorization: `Bearer ${accessToken}`,
      };

      if (requiresCsrf) {
        const csrfToken = await getCsrfToken();
        headers["CSRF-Token"] = csrfToken;
      }

      const response = await api.delete(route, { headers });
      return response.data;
    } catch (error) {
      return await handleAuthError(error);
    }
  };

  const post = async (route: string, data: any, requiresCsrf = false) => {
    try {
      const headers: Record<string, string> = {};

      if (requiresCsrf) {
        const csrfToken = await getCsrfToken();
        headers["CSRF-Token"] = csrfToken;
      }

      const response = await api.post(route, data, { headers });
      return response.data;
    } catch (error) {
      console.error("Post Request Failed", error);
      throw error;
    }
  };

  const get = async (route: string, requiresCsrf = false) => {
    try {
      const headers: Record<string, string> = {};

      if (requiresCsrf) {
        const csrfToken = await getCsrfToken();
        headers["CSRF-Token"] = csrfToken;
      }

      const response = await api.get(route, { headers });
      return response.data;
    } catch (error) {
      console.error("Get Request Failed", error);
      throw error;
    }
  };

  return {
    AxiosPostWithToken,
    AxiosGetWithToken,
    AxiosPutWithToken,
    AxiosDeleteWithToken,
    post,
    get,
  };
};
