import { useState } from "react";
import { useNotification } from "../../../core/hooks/useNotification";

interface CloseModalProps {
  cycleId: string;
  onClose: () => void;
  onCloseCycle: (id: string) => Promise<void>;
}

export const CloseModalContent: React.FC<CloseModalProps> = ({
  cycleId,
  onClose,
  onCloseCycle,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const notification = useNotification();

  const handleClose = async () => {
    try {
      setIsLoading(true);
      //   await onCloseCycle(cycleId);
      notification.success("Admission cycle closed successfully", {
        title: "Cycle Closed",
        duration: 5000,
      });
      onClose();
    } catch (error) {
      const errorMessage =
        error instanceof Error
          ? error.message
          : "Failed to close admission cycle";
      notification.error(errorMessage, {
        title: "Close Error",
        duration: 7000,
      });
      console.error("Failed to close cycle:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="text-start">
      <p className="mb-3">
        Are you sure you want to close this admission cycle? This action cannot
        be undone.
      </p>
      <div className="bg-light rounded border p-3">
        <div className="text-black fw-medium mb-2">Once closed:</div>
        <div>
          <div className="mb-1">No new applications can be submitted</div>
          <div className="mb-1">
            Existing applications will still be accessible
          </div>
          <div>The cycle status cannot be reverted back to active</div>
        </div>
      </div>
      <div className="d-flex justify-content-end mt-4">
        <button
          className="btn btn-light me-2"
          onClick={onClose}
          disabled={isLoading}
        >
          Cancel
        </button>
        <button
          className="btn btn-danger"
          onClick={handleClose}
          disabled={isLoading}
        >
          {isLoading ? (
            <>
              <span
                className="spinner-border spinner-border-sm me-2"
                role="status"
                aria-hidden="true"
              ></span>
              Closing...
            </>
          ) : (
            "Close Cycle"
          )}
        </button>
      </div>
    </div>
  );
};
