export const validateField = (
  name: string,
  value: any,
  required: boolean = false
): string => {
  if (required && (!value || value.length === 0)) {
    return `This field is required`;
  }

  switch (name.toLowerCase()) {
    case "mobile number":
    case "phone number":
      if (value && !/^[6-9]\d{9}$/.test(value)) {
        return "Please enter a valid 10-digit mobile number";
      }
      break;

    case "email":
      if (value && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
        return "Please enter a valid email address";
      }
      break;

    case "date of birth":
      if (value) {
        const date = new Date(value);
        const today = new Date();
        if (date > today) {
          return "Date of birth cannot be in the future";
        }
      }
      break;

    case "name in english":
    case "father's name":
    case "mother's name":
    case "guardian name":
      if (value && !/^[a-zA-Z\s.]{2,50}$/.test(value)) {
        return "Please enter a valid name (2-50 characters, letters only)";
      }
      break;

    case "blood group":
      if (value && !/^(A|B|AB|O)[+-]$/.test(value.toUpperCase())) {
        return "Please enter a valid blood group (e.g., A+, B-, O+, AB-)";
      }
      break;
  }

  return "";
};
