import { useState, useCallback } from "react";
import { FormErrors } from "./types";
import { validateField } from "./validation-utils";

export const useFormValidation = () => {
  const [errors, setErrors] = useState<FormErrors>({});

  const validateForm = useCallback(
    (formData: any): { isValid: boolean; firstErrorField?: string } => {
      const newErrors: FormErrors = {};
      const requiredFields = [
        "nameEnglish",
        "selectedGender",
        "dateOfBirth",
        "bloodGroup",
        "religion",
        "community",
        "caste",
        "motherTongue",
        "classAppliedFor",
        "address",
        "fatherName",
        "fatherPhoneNumber",
        "fatherOccupation",
        "motherName",
        "motherPhoneNumber",
        "motherOccupation",
        "identificationMarks",
        "previousSchoolName",
        "previousSchoolPeriodOfStudy",
        "previousSchoolClass",
      ];

      // Validate required fields
      requiredFields.forEach((field) => {
        const value = formData[field];
        const fieldName = field
          .replace(/([A-Z])/g, " $1")
          .toLowerCase()
          .trim();
        const error = validateField(fieldName, value, true);
        if (error) {
          newErrors[field] = error;
        }
      });

      // Validate guardian fields if hasGuardian is true
      if (formData.hasGuardian) {
        const guardianFields = [
          "guardianName",
          "guardianPhoneNumber",
          "guardianOccupation",
          "guardianRelation",
        ];
        guardianFields.forEach((field) => {
          const value = formData[field];
          const fieldName = field
            .replace(/([A-Z])/g, " $1")
            .toLowerCase()
            .trim();
          const error = validateField(fieldName, value, true);
          if (error) {
            newErrors[field] = error;
          }
        });
      }

      // Validate siblings if hasSiblings is true
      if (formData.hasSiblings && formData.siblingsData) {
        formData.siblingsData.forEach((sibling: any, index: number) => {
          ["name", "class", "section", "relation"].forEach((field) => {
            const value = sibling[field];
            const error = validateField(field, value, true);
            if (error) {
              newErrors[`sibling_${index}_${field}`] = error;
            }
          });
        });
      }

      setErrors(newErrors);
      const isValid = Object.keys(newErrors).length === 0;
      return {
        isValid,
        firstErrorField: isValid ? undefined : Object.keys(newErrors)[0],
      };
    },
    []
  );

  const clearError = useCallback((fieldName: string) => {
    setErrors((prev) => {
      const newErrors = { ...prev };
      delete newErrors[fieldName];
      return newErrors;
    });
  }, []);

  return {
    errors,
    validateForm,
    clearError,
  };
};
